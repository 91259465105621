import useAuth from '@crystal-eyes/hooks/useAuth'
import { queryWithAuth, gql } from '@crystal-eyes/utils/apis/graphqlApi'
import { subClient } from '@crystal-eyes/utils/apis/graphqlSub'
import useSWR, { mutate } from 'swr'

export const USAGE_QUERY = gql`
  query UseUsageStats {
    me {
      usageLimits {
        allowance
        usage
        actionNeeded
      }
    }
  }
`

export type State = {
  data?: { allowance: number; usage: number; actionNeeded: string }
  loading?: boolean
  error?: any
}

let subscribed: boolean = false
async function getUsageSub() {
  if (subscribed) return
  subscribed = true

  subClient()
    .then(async graphqlSub => {
      const subscription = graphqlSub.iterate({
        query: `
      subscription {
        usageChanged {
          allowance
          usage
          actionNeeded
        }
      }
      `,
      })

      for await (const event of subscription) {
        const usageChanged = event.data?.usageChanged
        if (usageChanged) mutate('global.user.usageStats', { usageChanged })
      }
    })
    .catch(_err => {
      // eslint-disable-next-line no-console
      console.error(`Error connecting to GraphQL subscription...`)
    })
}

export default function useUsageStats(): State {
  const { data: auth } = useAuth()

  getUsageSub()

  const fetcher = (__key: string) =>
    queryWithAuth(auth, USAGE_QUERY).then(({ me }) => me.usageLimits)

  const {
    data: queryData,
    isLoading: loading,
    error,
  } = useSWR(auth?.authed ? 'global.user.usageStats' : null, fetcher, {
    keepPreviousData: true,
    revalidateOnFocus: false,
    refreshInterval: 1000 * 60 * 60,
  })

  return {
    data: queryData,
    loading,
    error,
  }
}
