import { useState, useEffect } from 'react'
import useAuth from '../useAuth'
import useMeV2 from './useMeV2'

export type State = {
  data: boolean
  loading: boolean
}

export default function useIsFreeUser(): State {
  const { data: me } = useMeV2()
  const { data: auth } = useAuth()
  const [isFreeUser, setIsFreeUser] = useState(false)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (me && auth?.authed) {
      const hasPaidProduct = me.products?.some(
        product =>
          product === 'SALES' ||
          product === 'LEADERSHIP' ||
          product === 'HIRING',
      )
      setIsFreeUser(!hasPaidProduct)
      setLoading(false)
    }
  }, [me, auth?.authed])

  return {
    data: isFreeUser,
    loading,
  }
}
