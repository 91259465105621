'use client'

import React, { useEffect, useState } from 'react'
import styles from './NavLogoSection.module.scss'
import Image from 'next/image'
import Link from 'next/link'
import useWhitelabelSettings from '@crystal-eyes/hooks/user/useWhitelabelSettings'

export default function NavLogoSection({ expanded }: { expanded: boolean }) {
  const { data: whiteLabelSettings, loading: whitelabelLoading } =
    useWhitelabelSettings()
  const whitelabelLogo = whiteLabelSettings?.logoUrl
  const homePath = '/app/home'
  const [renderLogos, setRenderLogos] = useState<boolean>(false)
  useEffect(() => {
    setRenderLogos(true)
  }, [])

  const shouldRenderWhiteLabel = whitelabelLogo && expanded && renderLogos
  const shouldRenderCrystalLogo =
    !whitelabelLoading && !whitelabelLogo && renderLogos
  return (
    <>
      {!renderLogos && <div className={styles.emptyLogos}></div>}
      {shouldRenderWhiteLabel && (
        <div className={styles.whiteLabelArea}>
          <Link href={'/app'} aria-label="Homepage" prefetch={false}>
            <Image
              src={whitelabelLogo}
              alt="Crystal Logo"
              width={210}
              height={40}
            />
          </Link>
          <div className={styles.poweredBy}>powered by Crystal</div>
        </div>
      )}
      {shouldRenderCrystalLogo && (
        <div
          className={`${styles.navHeader} ${expanded ? styles.expanded : ''}`}
        >
          <Link href={homePath}>
            {expanded ? (
              <Image
                src="/static/share/images/crystal_svg_logo_white.svg"
                alt="Crystal Logo"
                width={150}
                height={37}
              />
            ) : (
              <Image
                src="/static/share/images/crystal_svg_logo_white_mini.svg"
                alt="Crystal Logo"
                width={37}
                height={37}
              />
            )}
          </Link>
        </div>
      )}
    </>
  )
}
