'use client'
import { useCallback, useEffect } from 'react'

export default function GoogleAnalytics() {
  const initGA = useCallback(() => {
    const windowWithDataLayer = window as unknown as Window & {
      dataLayer: unknown[]
    }
    windowWithDataLayer.dataLayer = windowWithDataLayer.dataLayer || []
    function gtag(...args: unknown[]) {
      windowWithDataLayer.dataLayer.push(args)
    }
    gtag('js', new Date())
    gtag('config', 'UA-57088246-2')
  }, [])

  useEffect(() => {
    initGA()
  }, [initGA])

  return null
}
