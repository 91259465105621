import React from 'react'
import classNamesBind from 'classnames/bind'
import ReactiveOverlay from '@dashboard/components/ReactiveOverlay'
import ReactiveOverlayFixed from '@dashboard/components/ReactiveOverlayFixed'

import css from './styles.module.scss'
const classNames = classNamesBind.bind(css)

const VerifiedIcon = '/static/share/images/verified.svg'

interface OwnProps {
  animate?: boolean
  size?: number
  noMargin?: boolean
  noHover?: boolean
  /** This is to handle cases where the tooltip is hidden due to overflow */
  fixedOverlay?: boolean
}

export default class VerifiedBadge extends React.Component<OwnProps> {
  render() {
    const { size, animate, noMargin, fixedOverlay } = this.props
    const badgeSize = size ? size : 16
    const OverlayComponent = fixedOverlay
      ? ReactiveOverlayFixed
      : ReactiveOverlay

    return (
      <div className={classNames('container', { animate, noMargin })}>
        <OverlayComponent
          withTransform
          preferredDirection="bottomRight"
          overlayContent={this.getOverlayContent()}
          overlayGap={8}
        >
          <img
            src={VerifiedIcon}
            className={classNames('badge')}
            style={{ width: `${badgeSize}px` }}
            alt="Verified Badge"
          />
        </OverlayComponent>
      </div>
    )
  }

  getOverlayContent = () => {
    const { noHover } = this.props

    if (noHover) return null

    return (
      <div className={classNames('tooltip')}>
        This is a verified profile, which is created and owned by a Crystal
        user.
      </div>
    )
  }
}
