import useSWR from 'swr'
import logger from '@crystal-eyes/utils/logger'
import { DiscType } from '@crystal-eyes/types'
import useAuth from '@crystal-eyes/hooks/useAuth'
import useIsOwnProfile from '@crystal-eyes/hooks/profiles/context/useIsOwnProfile'
import { fetchV3ApiAuthed } from '@crystal-eyes/utils/apis/v3Api'
import usePersonality from '@crystal-eyes/hooks/profiles/usePersonality'
import useProfileId from '@crystal-eyes/hooks/profiles/useProfileId'

interface Identity {
  id: string
}

type Options = { identity?: Identity }

type EditProfileOption = 'none' | 'edit-profile'

type ProfileCreationProps = {
  firstName: string
  lastName: string
  gender: string
  discType: DiscType
  intensity: number
}

type State = {
  setMyPersonality: (discType: DiscType, intensity: number) => Promise<void>
  createUnownedProfile: (props: ProfileCreationProps) => Promise<void>
  editProfileState: {
    state: EditProfileOption
    setState: (newValue: EditProfileOption) => void
  }
}

let sharedEditProfile: EditProfileOption = 'none'
export default function useEditProfile({ identity }: Options): State {
  const { data: auth } = useAuth()
  const { data: isOwnProfile } = useIsOwnProfile({ identity })
  const { mutate: refetchPersonality } = usePersonality({ identity })
  const { data: profileId } = useProfileId({ identity: identity! })

  const { data: editProfileState, mutate: setEditProfileState } = useSWR(
    'useEditProfile.editProfile',
    () => {
      if (sharedEditProfile !== 'none')
        logger.info('Editing Profile', sharedEditProfile)

      return sharedEditProfile
    },
    {
      keepPreviousData: true,
      revalidateOnFocus: false,
    },
  )

  const setMyPersonality = async (discType: DiscType, intensity: number) => {
    if (isOwnProfile) {
      return fetchV3ApiAuthed(auth, 'me/personality', {
        method: 'PUT',
        body: JSON.stringify({
          personality: { disc_type: discType, intensity },
        }),
      }).then(() => {
        refetchPersonality()
      })
    }
  }

  const createUnownedProfile = async ({
    firstName,
    lastName,
    gender,
    discType,
    intensity,
  }: ProfileCreationProps) => {
    return fetchV3ApiAuthed(auth, `unowned_profiles/${profileId}`, {
      method: 'PUT',
      body: JSON.stringify({
        personality: { disc_type: discType, intensity },
        profile: {
          first_name: firstName,
          last_name: lastName,
          gender: gender,
        },
      }),
    }).then(() => {
      refetchPersonality()
    })
  }

  return {
    setMyPersonality,
    createUnownedProfile,
    editProfileState: {
      state: editProfileState || 'none',
      setState: (newValue: EditProfileOption) => {
        sharedEditProfile = newValue
        setEditProfileState(newValue)
      },
    },
  }
}
