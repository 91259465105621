import { queryWithAuth, gql } from '@crystal-eyes/utils/apis/graphqlApi'
import getAuth from '@crystal-eyes/data/getAuth'

interface Identity {
  id: string
}

type VerificationStatus = {
  isCrystalUser: boolean
  isCrystalManaged: boolean
  isEdited: boolean
}

export default async function getVerifiedStatus({
  identity,
}: {
  identity: Identity
}): Promise<VerificationStatus | undefined> {
  const auth = await getAuth()

  if (auth.authToken) {
    return queryWithAuth(auth, VERIFIED_STATUS_QUERY, {
      identityId: identity.id,
    }).then(resp => ({
      isCrystalUser: resp?.identity?.isCrystalUser,
      isCrystalManaged: resp?.identity?.isCrystalManaged,
      isEdited:
        resp?.identity?.snapshot?.hasBeenEdited ||
        resp?.identity?.rootSnapshot?.hasBeenEdited,
    }))
  } else {
    return
  }
}

const VERIFIED_STATUS_QUERY = gql`
  query getVerifiedStatus($identityId: Guid!) {
    identity(id: $identityId) {
      id
      isCrystalUser
      isCrystalManaged

      rootSnapshot {
        hasBeenEdited
      }

      snapshot {
        hasBeenEdited
      }
    }
  }
`
