import { useState, useEffect } from 'react'
import { gql, queryWithAuth } from '@crystal-eyes/utils/apis/graphqlApi'
import useAuth from '@crystal-eyes/hooks/useAuth'
import useMeV2 from '@crystal-eyes/hooks/user/useMeV2'
import { useRouter } from 'next/navigation'
import { rsEvent } from '@dashboard/lib/analytics'

const UPDATE_SIDE_NAVIGATION_FLAG = gql`
  mutation UpdateSideNavigationFlag($enabled: Boolean!) {
    updateSideNavigationFlag(enabled: $enabled) {
      sideNavigation
    }
  }
`

type State = {
  data: boolean
  loading: boolean
  error: any
  update: (ispassingEnabled: boolean) => void
}

export default function useSideNavFlag(): State {
  const { data: auth } = useAuth()
  const { data: me } = useMeV2()
  const router = useRouter()
  const [isEnabled, setIsEnabled] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [errorState, setErrorState] = useState<string | null>(null)

  useEffect(() => {
    setIsEnabled(me?.sidenavFeatureFlag || false)
  }, [me])

  const navigateToProfile = () => {
    router.push('/app')
  }

  const navigateToHome = () => {
    router.push('/app/home')
    router.refresh()
  }

  const doUpdate = (ispassingEnabled: boolean) => {
    setIsLoading(true)
    queryWithAuth(auth, UPDATE_SIDE_NAVIGATION_FLAG, {
      enabled: ispassingEnabled,
    })
      .then(res => {
        const action = res?.updateSideNavigationFlag.sideNavigation
          ? 'on'
          : 'off'
        rsEvent('Premium Dashboard Toggle', {
          action: action,
          path: window.location.pathname,
          source: 'dashboard',
        })
        const sideNavigationEnabled =
          res?.updateSideNavigationFlag.sideNavigation
        setIsEnabled(sideNavigationEnabled)
        setIsLoading(false)
        if (sideNavigationEnabled) {
          navigateToHome()
        } else {
          navigateToProfile()
        }
      })
      .catch(err => {
        setErrorState(err)
        setIsLoading(false)
      })
  }

  return {
    data: isEnabled,
    loading: isLoading,
    error: errorState,
    update: doUpdate,
  }
}
