import { Api } from '@dashboard/lib/api'
import { DEV_MODE } from './constants'
import { rsEvent } from './analytics/helpers'
import { noticeError } from '@crystal-eyes/utils/instrumentation'
export {
  pathnameHelper,
  rsEvent,
  rsPage,
  rsIdentify,
  rsGroup,
} from './analytics/helpers'

const HUBSPOT_PORTAL_ID = '1716276'
const SUBMIT_USER_FEEDBACK_HUBSPOT_FORM_ID =
  'ed697523-82d1-43af-8c6d-fedd345e3447'

export const HSSubmitFormData = (
  portalID: string,
  formGUUID: string,
  email: string,
  fields: Array<{ name: string; value: any }>,
  options?: { context: { pageUri: string; pageName: string } },
) => {
  if (!email || DEV_MODE) return

  fields.push({ name: 'email', value: email })

  return Api()
    .post(
      `https://api.hsforms.com/submissions/v3/integration/submit/${portalID}/${formGUUID}`,
      {
        fields,
        skipValidation: true,
        ...options,
      },
    )
    .catch(e => {
      noticeError('HSSubmitFormData Error', e)
    })
}

function submitUserFeedback(
  email: string,
  feedback: string,
  subject = 'User Feedback - Dashboard',
) {
  HSSubmitFormData(
    HUBSPOT_PORTAL_ID,
    SUBMIT_USER_FEEDBACK_HUBSPOT_FORM_ID,
    email,
    [
      {
        name: 'subject',
        value: subject,
      },
      {
        name: 'content',
        value: feedback,
      },
    ],
  )
}

export function submitPageFeedback(
  email: string,
  page: string,
  feature: string,
  feedback: string,
) {
  const subject = `User Feedback - ${page}${feature}`
  submitUserFeedback(email, feedback, subject)
}

export function submitEnterprisePricingRequest(
  email: string,
  companySize: string,
) {
  const subject = 'Enterprise Pricing Request'
  const content = `${email} would like to learn more about enterprise pricing. Number of licenses: ${companySize}`
  submitUserFeedback(email, content, subject)
}

export function submitCreditCardFailedMessage(email: string) {
  const subject = 'Credit Card Failed'
  const content = `${email} has a failed credit card when trying to purchase. Please reach out to user.`
  submitUserFeedback(email, content, subject)
}

export function submitCancellationAttemptCallRequest(
  email: string,
  phoneNumber: string,
) {
  const subject = 'Cancellation Attempt - Call Request'
  const content = `${email} has attempted to cancel their account. Please reach out to user at ${phoneNumber} .`
  submitUserFeedback(email, content, subject)
}

export function submitCancellationFeedback(email: string, feedback: string) {
  const subject = 'Cancellation Feedback - Details'
  submitUserFeedback(email, feedback, subject)
}

export function submitPlaybookSectionRequest(email: string, section: string) {
  const subject = 'Feedback - New Playbook Section'
  submitUserFeedback(email, section, subject)
}

const CREATE_FREE_USER_HUBSPOT_FORM_ID = '54ccc630-5ee2-4c72-ac0b-5a99110be16d'
export function newUserRegistered(
  email: string,
  firstName?: string,
  lastName?: string,
  company?: string | null,
  partner?: string | null,
) {
  const params = [
    {
      name: 'firstname',
      value: firstName,
    },
    {
      name: 'lastname',
      value: lastName,
    },
    {
      name: 'company',
      value: company,
    },
    {
      name: 'partner',
      value: partner,
    },
  ].filter(param => param.value)

  HSSubmitFormData(
    HUBSPOT_PORTAL_ID,
    CREATE_FREE_USER_HUBSPOT_FORM_ID,
    email,
    params,
    {
      context: {
        pageUri: 'https://www.crystalknows.com/app/register',
        pageName: 'Get Started - Free Crystal Account',
      },
    },
  )
}

export function completedAssessment() {
  rsEvent('Assessment Completed')
}
