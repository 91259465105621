'use client'

import React from 'react'
import Link from 'next/link'
import type { NavSubItem } from './types'
import Icon, { Icons } from '@crystal-eyes/components/elements/Icon/Icon'
import styles from './NavItem.module.scss'

type Props = {
  item: NavSubItem
  active?: boolean
  icon?: Icons
  expanded?: boolean
}

export default function NavItem({
  item,
  active = false,
  icon,
  expanded,
}: Props) {
  return (
    <Link
      href={item.path}
      key={item.path}
      className={` ${styles.navItem} ${active ? styles.selected : ''} ${
        expanded ? styles.expanded : ''
      }`}
    >
      {icon && <Icon icon={icon} />}
      {item.title}
    </Link>
  )
}
