'use client'

import React from 'react'
import styles from './UsageToken.module.scss'
import Icon, { Icons } from '@crystal-eyes/components/elements/Icon/Icon'

type Props = {
  credits: number
  onClick?: () => void
  expanded?: boolean
}

export default function UsageToken({ credits, onClick, expanded }: Props) {
  return (
    <button
      className={`${styles.usageToken} ${onClick ? styles.clickable : ''} ${
        expanded ? styles.expanded : ''
      }`}
      onClick={onClick}
    >
      {expanded && <Icon icon={Icons.User} className={styles.icon} />}
      <div className={`CE title small bold white`}>{`${
        expanded ? 'Credits: ' : ''
      }${credits}`}</div>
    </button>
  )
}
