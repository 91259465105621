import {
  FullAuthUser,
  AuthUserProfile,
  Profile,
  PublicProfile,
  ProfileModuleName,
} from '@dashboard/lib/types'
import { EmailsList } from '@crystal-eyes/types'
export type { ProfileModuleName } from '@dashboard/lib/types'

export function mockedAuthProfile(
  attrs: Partial<AuthUserProfile> = {},
): AuthUserProfile {
  const emptyProfile: AuthUserProfile = {
    first_name: '',
    id: '',
    last_name: '',
    photo_url: '',
    type: '',
    current_associations: [] as string[],
    personality: {
      archetype: 'Driver',
      degrees: 117,
      disc_type: 'Di',
      intensity: 81,
      four_percentages: {
        d: 71,
        i: 24,
        s: 3,
        c: 2,
      },
      updated_by: false,
      created_at: '2022-08-29T19:52:09.000Z',
      updated_at: '2022-08-29T19:52:09.000Z',
    },
    verified: false,
  }

  return { ...emptyProfile, ...attrs }
}

export function mockedProfile(attrs: Partial<Profile> = {}): Profile {
  const emptyProfile: Profile = {
    company_name: null,
    enneagram_text_type: null,
    first_name: '',
    gender: null,
    id: '',
    job_title: null,
    last_name: '',
    photo_url: '',
    profile_root: '',
    type: '',
    current_associations: [] as string[],
    myers_briggs_text_type: null,
    personality: {
      archetype: 'Driver',
      degrees: 117,
      disc_type: 'Di',
      intensity: 81,
      four_percentages: {
        d: 71,
        i: 24,
        s: 3,
        c: 2,
      },
      updated_by: false,
      created_at: '2022-08-29T19:52:09.000Z',
      updated_at: '2022-08-29T19:52:09.000Z',
    },
    life_values: {},
    strengths: {},
    sources: [] as string[],
    vanity_url: null,
    accuracies: null,
    privacy: 'global',
    notes: null,
    verified: false,
    editable: false,
    product_access: null,
    correlation_invite_token: null,
    job_fit: null,
    status: null,
  }

  return { ...emptyProfile, ...attrs }
}

export const profileJobString = (profile: Profile) => {
  if (profile.job_title && profile.company_name) {
    return `${profile.job_title} at ${profile.company_name}`
  } else if (profile.job_title) {
    return profile.job_title
  } else if (profile.company_name) {
    return profile.company_name
  }

  return null
}

export const IsUserProfileWithPersonality = (
  profile: AuthUserProfile | Profile,
) => {
  return isUserProfile(profile) && profile.personality
}

function isUserProfile(profile: AuthUserProfile | Profile) {
  return profile.verified
}

export const getInitials = (profile: {
  first_name: string
  last_name: string
}) => {
  let initials = ''
  if (profile.first_name) initials += profile.first_name.slice(0, 1)
  if (profile.last_name) initials += profile.last_name.slice(0, 1)
  return initials
}

export const fullName = (entity: {
  first_name?: string
  last_name?: string
}) => {
  let fullName = ''
  if (entity.first_name && entity.last_name)
    fullName = `${entity.first_name} ${entity.last_name}`
  else if (entity.first_name) fullName = entity.first_name
  else if (entity.last_name) fullName = entity.last_name
  return fullName
}

export const firstNamePossessive = (profile: { first_name: string }) => {
  const { first_name } = profile

  if (!first_name) return ''
  else if (first_name.endsWith('s')) return `${first_name}'`
  else return `${first_name.trim()}'s`
}

export const profileDiscLetter = (
  profile: Profile | null,
  uppercase = false,
) => {
  let discType = ''

  if (profile && profile.personality && profile.personality.disc_type) {
    discType = profile.personality.disc_type.slice(0, 1).toLowerCase()
  }

  return uppercase ? discType.toUpperCase() : discType
}

export const profileGenderPronoun = (profile: Profile) => {
  if (profile && profile.gender == 'male') return 'him'
  else if (profile && profile.gender == 'female') return 'her'
  else return 'them'
}

export const profileQuery = (
  page?: number,
  filters?: Array<string>,
  query?: string,
  limit?: number,
  traitKey?: number,
) => {
  const queryObj = {
    page,
    filters: (filters || []).sort().join(','),
    query,
    limit,
    traitKey,
  }

  return JSON.stringify(queryObj)
}

export function isProfile(
  profile: Profile | PublicProfile,
): profile is Profile {
  return 'personality' in profile
}

export function getShareId(profile: Profile) {
  return profile.vanity_url || profile.id
}

type FilteredModules = Partial<Record<ProfileModuleName, boolean>>

export const assessmentValueList = (authUser: FullAuthUser) => {
  const { big_five, enneagram, myers_briggs, strengths, life_values } =
    authUser.team?.profile_assessments
  const assessmentToShowList: FilteredModules = {
    big_five: !!big_five,
    enneagram: !!enneagram,
    myers_briggs: !!myers_briggs,
    strengths_assessment: !!strengths,
    values_assessment: !!life_values,
  }
  return assessmentToShowList
}

export const getPrimaryEmailFromList = (emails: EmailsList) => {
  if (!emails) return null
  const primaryEmails = emails.filter(email => email.type === 'primary')
  const primaryEmail = primaryEmails.length
    ? primaryEmails?.[0].value
    : emails[0].value
  return primaryEmail
}
