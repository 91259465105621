'use client'

import styles from './SecondaryNavigation.module.scss'
import Link from 'next/link'
import Icon, { Icons } from '@crystal-eyes/components/elements/Icon/Icon'
import usePrint from '@dashboard/app/p/[slug]/hooks/usePrint'
import useAuth from '@crystal-eyes/hooks/useAuth'
import useEditProfile from '@dashboard/app/p/[slug]/hooks/useEditProfile'
import useTagging from '@dashboard/app/p/[slug]/hooks/useTagging'
import useVerifiedStatus from '@crystal-eyes/hooks/profiles/context/useVerifiedStatus'
import useIsOwnProfile from '@crystal-eyes/hooks/profiles/context/useIsOwnProfile'
import useCanDeleteProfile from '@crystal-eyes/hooks/profiles/context/useCanDeleteProfile'
import logger from '@crystal-eyes/utils/logger'
import SecondaryNavigation from './SecondaryNavigation'
import { usePathname } from 'next/navigation'
import { useEffect } from 'react'
import { identify, track } from '@dashboard/lib/analytics_v2'
import useMeV2 from '@crystal-eyes/hooks/user/useMeV2'
import useProfileId from '@crystal-eyes/hooks/profiles/useProfileId'
import usePersonality from '@crystal-eyes/hooks/profiles/usePersonality'
import usePersonalInfo from '@crystal-eyes/hooks/profiles/usePersonalInfo'
import useRelationship from '@crystal-eyes/hooks/profiles/context/useRelationship'

export default function ProfileSubNav() {
  const pathname = usePathname()
  const slug = pathname?.replace('/p/', '')
  const identity = { id: `snap:${slug}` }
  const { authed, data: authDetails } = useAuth()
  const { data: me } = useMeV2()
  const { data: profileId } = useProfileId({ identity: identity! })
  const { data: personality } = usePersonality({ identity })
  const { data: personalInfo } = usePersonalInfo({ identity })
  const { data: verifiedStatus } = useVerifiedStatus({ identity })
  const { data: isOwnProfile } = useIsOwnProfile({ identity })
  const { data: canDeleteProfile, deleteProfile } = useCanDeleteProfile({
    identity,
  })
  const { data: relationship } = useRelationship({ identity })

  useEffect(() => {
    // analytics
    if (authed && me && profileId) {
      if (relationship?.isCandidate) {
        track('Candidate Profile Viewed', {
          candidate_id: profileId,
          candidate_disc: personality?.discType || '',
        })
      } else if (relationship?.isCoworker) {
        track('Team Profile Viewed', {
          profile_id: profileId,
          profile_name: personalInfo?.fullName || '',
        })
      } else {
        track('Customer Profile Viewed', { custom_id: profileId })
      }
      const primaryEmail = me?.emails?.[0]?.value
      if (authDetails?.jwtPayload?.profile_id && primaryEmail)
        identify(
          authDetails?.jwtPayload?.profile_id,
          {
            date_of_last_dashboard_login: Date.now(),
            hubspot_organization_id: authDetails?.jwtPayload?.org_id || '',
            firstName: authDetails?.jwtPayload?.first_name || '',
            lastName: authDetails?.jwtPayload?.last_name || '',
            email: primaryEmail,
          },
          { groupId: authDetails?.jwtPayload?.org_id || '' },
        )
    }
    if (!isOwnProfile) {
      track('Dashboard Profile Viewed')
    }
  }, [identity, authed, profileId, personality, authDetails])

  const {
    modalState: { setShow: setShowPrintModal },
  } = usePrint()

  const {
    editProfileState: { setState: setEditProfile },
  } = useEditProfile({ identity })

  const {
    taggingState: { setState: setTagging },
  } = useTagging({ identity })

  const allowEdits =
    authed &&
    (isOwnProfile ||
      (!verifiedStatus?.isCrystalUser && !verifiedStatus?.isCrystalManaged))

  return (
    <SecondaryNavigation>
      {authed && (
        <div className={styles.utilities}>
          <Link
            href="#"
            onClick={evt => {
              evt.preventDefault()
              setShowPrintModal(true)
            }}
          >
            <Icon icon={Icons.Download} className={styles.icon} /> Download
          </Link>

          {canDeleteProfile && (
            <Link
              href="#"
              onClick={evt => {
                evt.preventDefault()
                if (
                  confirm(
                    'Are you sure you want to permanently delete this profile?',
                  )
                ) {
                  deleteProfile()
                    .then(() => (window.location.href = '/app'))
                    .catch(err => {
                      logger.error('Delete Profile Error', err)

                      alert('Something went wrong deleting the profile!')
                    })
                }
              }}
            >
              <Icon icon={Icons.Trash} className={styles.icon} /> Delete
            </Link>
          )}

          {allowEdits && (
            <Link
              href="#"
              onClick={evt => {
                evt.preventDefault()
                setEditProfile('edit-profile')
              }}
            >
              <Icon icon={Icons.Edit} className={styles.icon} /> Edit
            </Link>
          )}

          <Link
            href="#"
            onClick={evt => {
              evt.preventDefault()
              setTagging('tagging-profile')
            }}
          >
            <Icon icon={Icons.Tag} className={styles.icon} /> Tags
          </Link>
        </div>
      )}
    </SecondaryNavigation>
  )
}
