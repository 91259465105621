import { queryWithAuth, gql } from '@crystal-eyes/utils/apis/graphqlApi'
import getAuth from '@crystal-eyes/data/getAuth'
import getUserIdentity from '@crystal-eyes/data/getUserIdentity'
import DataTags from '@crystal-eyes/data/dataTags'

const FETCH_OPTS = { next: { tags: [DataTags.UserData] } }

export default async function getIsOwnProfile({
  identity,
}: {
  identity: { id: string }
}): Promise<boolean> {
  const auth = await getAuth()
  if (!auth?.authToken) {
    return false
  }

  const [userIdentity, targetIdentity] = await Promise.all([
    getUserIdentity(),
    queryWithAuth(auth, IDENTITY_QUERY, { id: identity.id }, FETCH_OPTS).then(
      res => res.identity,
    ),
  ])

  return userIdentity?.id === targetIdentity.id
}

const IDENTITY_QUERY = gql`
  query getIsOwnProfile($id: Guid!) {
    identity(id: $id) {
      id
    }
  }
`
