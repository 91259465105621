import { queryWithAuth, gql } from '@crystal-eyes/utils/apis/graphqlApi'
import useAuth from '@crystal-eyes/hooks/useAuth'
import useSWR from 'swr'

type State = {
  data?: { logoUrl?: string }
  loading?: boolean
  error?: any
}

export const WHITELABEL_SETTINGS_QUERY = gql`
  query GetWhitelabelSettings {
    whiteLabelSettings {
      logoUrl
    }
  }
`

export default function useWhitelabelSettings(): State {
  const { data: auth } = useAuth()

  const fetcher = (__key: string) =>
    queryWithAuth(auth, WHITELABEL_SETTINGS_QUERY)

  const {
    data: queryData,
    isLoading: loading,
    error,
  } = useSWR(auth ? 'global.user.whitelabel_settings' : null, fetcher, {
    keepPreviousData: true,
    revalidateOnFocus: false,
    refreshInterval: 1000 * 60 * 60 * 24,
  })

  return {
    data: (queryData as any)?.whiteLabelSettings,
    loading,
    error,
  }
}
