'use client'
import { enrichmentApiHost } from '@crystal-eyes/config'
import { createClient, Client } from 'graphql-ws'
import getAuth from '@crystal-eyes/data/getAuth'

const baseHost =
  enrichmentApiHost
    ?.replace('https://', 'wss://')
    .replace('http://', 'ws://') || ''

let client: Client | null = null
export async function subClient(): Promise<Client> {
  if (!client) {
    const auth = await getAuth()
    if (auth.authed) {
      client = createClient({
        url: `${baseHost}/api/graphql-ws`,
        connectionParams: { jwt: auth.authToken },
      })
    } else {
      return new Promise(resolve =>
        setTimeout(() => {
          resolve(subClient())
        }, 1000),
      )
    }
  }

  return client
}
