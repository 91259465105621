'use client'
import styles from './SecondaryNavigation.module.scss'
import useSWR from 'swr'
import Icon, { Icons } from '@crystal-eyes/components/elements/Icon/Icon'
import { useEffect } from 'react'
import { throttle } from 'throttle-debounce'

const CONTENT_SELECTOR = '#content'
const WATCH_DOM_ELEMENTS = ['H1', 'H2', 'SECTION']
const SECTION_HIGHLIGHT_OFFSET = 100

type Heading = { id: string; title: string }
export type Section = {
  heading: Heading
  subHeadings: Heading[]
  icon?: Icons
}

type Props = {
  page?: string
  children?: React.ReactNode
}

const restrictedSubHeaderIds = ['profile_completeness']

export default function SecondaryNavigation({ page, children }: Props) {
  const { data: sections = [], mutate: refetchHeadings } = useSWR(
    `${page || 'profile'}-page-sections`,
    sectionsFetcher,
    {
      keepPreviousData: true,
      revalidateIfStale: true,
      dedupingInterval: 10000000,
    },
  )

  const { data: activeHeadings = [], mutate: refetchActiveHeadings } = useSWR(
    `${page || 'profile'}-page-active-headings`,
    activeSectionsFetcher,
    {
      keepPreviousData: true,
      revalidateIfStale: true,
      dedupingInterval: -1,
    },
  )

  useEffect(() => {
    refetchHeadings()
    const refetchTimeout = setTimeout(() => {
      refetchHeadings()
    }, 500)

    const content = document.querySelector(CONTENT_SELECTOR)!

    const { stop: stopWatchingHeaders } = observeHeadingChanges(
      content,
      refetchHeadings,
    )

    const { stop: stopWatchingScroll } = observeScroll(
      content,
      refetchActiveHeadings,
    )

    return () => {
      stopWatchingHeaders()
      stopWatchingScroll()
      if (refetchTimeout) clearTimeout(refetchTimeout)
    }
  }, [])

  return (
    <nav
      id={`${page || 'profile'}-secondary-navigation`}
      className={styles.secondaryNavigation}
    >
      <div className={styles.navigationContainer}>
        {sections.map(section => (
          <section
            data-section={section.heading.id}
            key={section.heading.id}
            className={`${
              activeHeadings.includes(section.heading.id)
                ? styles.active
                : styles.inactive
            }`}
          >
            <div
              className={`${styles.heading} ${
                activeHeadings.includes(section.heading.id) ? styles.active : ''
              }`}
            >
              {section.icon && <Icon icon={section.icon} />}

              <a href={`#${section.heading.id}`}>{section.heading.title}</a>
            </div>
            <div className={styles.subHeadingSection}>
              {section.subHeadings.map(subheading => (
                <div
                  className={`${styles.subheading} ${
                    activeHeadings.includes(subheading.id) ? styles.active : ''
                  }`}
                  key={subheading.id}
                >
                  <a href={`#${subheading.id}`}>{subheading.title}</a>
                </div>
              ))}
            </div>
          </section>
        ))}
        {children}
      </div>
    </nav>
  )
}

const sectionsFetcher = async () => {
  if (typeof document === 'undefined') return []

  const content = document.querySelector(CONTENT_SELECTOR)

  const sections: Section[] = []
  content?.querySelectorAll('section').forEach(section => {
    const headingElem = section.querySelector('h1')!
    const icon = headingElem.dataset['icon'] as Icons
    const headingSidebarTitle = headingElem.dataset['sidebarTitle'] as Icons

    const heading: Heading = {
      id: headingElem.id,
      title: headingSidebarTitle || headingElem.textContent || '',
    }

    const subHeadings: Heading[] = []
    const subheadingElems = section.querySelectorAll('h2')
    subheadingElems.forEach(subheading => {
      if (restrictedSubHeaderIds.includes(subheading.id)) return
      const subheadingSidebarTitle = subheading.dataset['sidebarTitle'] as Icons

      subHeadings.push({
        id: subheading.id,
        title: subheadingSidebarTitle || subheading.textContent || '',
      })
    })

    if (subHeadings.length > 0) {
      sections.push({
        heading,
        subHeadings,
        icon,
      })
    }
  })

  return sections
}

const activeSectionsFetcher = (): string[] => {
  const content = document.querySelector(CONTENT_SELECTOR)!

  let firstHeading: Element | undefined
  let activeHeading: Element | undefined
  content.querySelectorAll('h1').forEach((heading: Element) => {
    if (!firstHeading) firstHeading = heading
    if (heading.getBoundingClientRect().top < SECTION_HIGHLIGHT_OFFSET) {
      activeHeading = heading
    }
  })

  let activeSubheading: Element | undefined
  let lastSubheadingBeforeFold: Element | undefined
  content.querySelectorAll('h2').forEach((subHeading: Element) => {
    const isRestricted = restrictedSubHeaderIds.includes(subHeading.id)
    const boundingRect = subHeading.getBoundingClientRect()

    if (boundingRect.top < SECTION_HIGHLIGHT_OFFSET && !isRestricted) {
      lastSubheadingBeforeFold = subHeading
    }

    if (
      !activeSubheading &&
      boundingRect.top > 0 &&
      boundingRect.top < SECTION_HIGHLIGHT_OFFSET &&
      !isRestricted
    ) {
      activeSubheading = subHeading
    }
  })

  const activeElems: string[] = []
  if (activeHeading) activeElems.push(activeHeading.id)
  if (!activeHeading && firstHeading) activeElems.push(firstHeading.id)

  if (activeSubheading) activeElems.push(activeSubheading.id)
  if (!activeSubheading && lastSubheadingBeforeFold)
    activeElems.push(lastSubheadingBeforeFold.id)

  return activeElems
}

const observeHeadingChanges = (
  content: Element,
  refetchHeadings: any,
): { stop: () => void } => {
  const observer = new MutationObserver(mutations => {
    let shouldRefetchHeadings = false
    mutations.forEach(mutation => {
      if (mutation.type !== 'childList') return

      if (WATCH_DOM_ELEMENTS.includes(mutation.target?.nodeName)) {
        shouldRefetchHeadings = true
      }

      mutation.addedNodes.forEach(addedNode => {
        if (WATCH_DOM_ELEMENTS.includes(addedNode.nodeName))
          shouldRefetchHeadings = true
      })

      mutation.removedNodes.forEach(removedNode => {
        if (WATCH_DOM_ELEMENTS.includes(removedNode.nodeName))
          shouldRefetchHeadings = true
      })
    })

    if (shouldRefetchHeadings) refetchHeadings()
  })

  observer.observe(content!, {
    childList: true,
    subtree: true,
  })

  return {
    stop: () => {
      observer.disconnect()
    },
  }
}

const observeScroll = (
  _content: Element,
  refetchActiveHeadings: any,
): { stop: () => void } => {
  const handleScroll = throttle(200, () => {
    refetchActiveHeadings()
  })

  window.addEventListener('scroll', handleScroll)
  return {
    stop: () => {
      window.addEventListener('scroll', handleScroll)
    },
  }
}
