import { DiscType } from '@crystal-eyes/types'
import { queryWithAuth, gql } from '@crystal-eyes/utils/apis/graphqlApi'
import useAuth from '@crystal-eyes/hooks/useAuth'
import useSWR from 'swr'

type Identity = {
  id: string
  isCrystalUser: boolean
  isCrystalManaged: boolean

  personalInfo: {
    firstName?: string
    lastName?: string
    fullName?: string
    photoUrl?: string
    companyName?: string
    jobTitle?: string
  }

  rootSnapshot: {
    id: string
    vanityUrl?: string
    hasBeenEdited?: boolean
  }

  snapshot: {
    id: string
    hasBeenEdited?: boolean
    personality?: {
      discType: DiscType
      discCircumplez?: {
        degrees: number
        intensity: number
      }
    }
  }
}

type State = {
  data?: Identity
  loading?: boolean
  error?: any
}

export const USER_IDENTITY_QUERY = gql`
  query UseUserIdentity {
    me {
      identity {
        id
        isCrystalUser
        isCrystalManaged

        personalInfo {
          firstName
          lastName
          fullName
          photoUrl
          companyName
          jobTitle
        }

        rootSnapshot {
          id
          vanityUrl
          hasBeenEdited
        }

        snapshot {
          id
          hasBeenEdited
          personality {
            id
            discType
            discArchetype
            discCircumplex {
              degrees
              intensity
            }
          }
        }
      }
    }
  }
`

export default function useUserIdentity(): State {
  const { data: auth } = useAuth()
  const fetcher = (__key: string) =>
    queryWithAuth(auth, USER_IDENTITY_QUERY).then(data => data.me)

  const {
    data: identityData,
    isLoading: loading,
    error,
  } = useSWR(auth ? 'global.user.identity' : null, fetcher, {
    keepPreviousData: true,
    revalidateOnFocus: false,
    refreshInterval: 1000 * 60 * 60,
  })

  return {
    data: (identityData as any)?.identity,
    loading,
    error,
  }
}
