import useAuth from '@crystal-eyes/hooks/useAuth'
import useProfileId from '@crystal-eyes/hooks/profiles/useProfileId'
import { fetchV3ApiAuthed } from '@crystal-eyes/utils/apis/v3Api'
import useVerifiedStatus from '@crystal-eyes/hooks/profiles/context/useVerifiedStatus'
import useIsOwnProfile from '@crystal-eyes/hooks/profiles/context/useIsOwnProfile'
import useSWRMutation from 'swr/mutation'
import { useCallback } from 'react'

interface Identity {
  id: string
}

type Options = {
  identity?: Identity
}

export type State = {
  data?: boolean
  deleteProfile: () => Promise<any>
}

export default function useCanDeleteProfile({ identity }: Options = {}): State {
  const { data: auth, authed } = useAuth()
  const { data: profileId } = useProfileId({ identity: identity! })
  const {
    data: verifiedStatus,
    loading: verifiedStatusLoading,
    error: verifiedStatusError,
  } = useVerifiedStatus({ identity })
  const { data: isOwnProfile } = useIsOwnProfile({ identity })

  const data =
    authed &&
    !verifiedStatusError &&
    !verifiedStatusLoading &&
    !isOwnProfile &&
    !verifiedStatus?.isCrystalUser &&
    !verifiedStatus?.isCrystalManaged

  const { trigger: deleteProfileWithId } = useSWRMutation(
    'useCanDeleteProfile.deleteProfile',
    (__key: string, { arg: profileId }: { arg: string }) =>
      fetchV3ApiAuthed(auth, `/unowned_profiles/${profileId}`, {
        method: 'DELETE',
      }),
  )

  const deleteProfile = useCallback(() => {
    return profileId
      ? deleteProfileWithId(profileId)
      : Promise.reject('No Profile ID found')
  }, [profileId, deleteProfileWithId])

  return {
    data,
    deleteProfile,
  }
}
