import React, { FC } from 'react'
import styles from '../styles.module.scss'
import { SubComponentProps } from '../types'

const Header: FC<SubComponentProps> = ({ className, children }) => {
  return (
    <th
      className={`CE title normal gray semi-bold ${styles.tableHeader} ${
        className ? className : ''
      }`}
    >
      {children}
    </th>
  )
}

export default Header
