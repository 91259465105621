import useSWR from 'swr'
import getVerifiedStatus from '@crystal-eyes/data/profiles/getVerifiedStatus'

interface Identity {
  id: string
  isCrystalUser?: boolean
  isCrystalManaged?: boolean
  snapshot?: {hasBeenEdited?: boolean}
  rootSnapshot?: {hasBeenEdited?: boolean}
}

type Options = {
  identity?: Identity
}

type VerificationStatus = {
  isCrystalUser: boolean
  isCrystalManaged: boolean
  isEdited: boolean
}

export type State = {
  data?: VerificationStatus
  loading?: boolean
  error?: any
}

export default function useVerifiedStatus({ identity }: Options): State {
  const fetcher = ([__key, identityId]: [string, string]) =>
    getVerifiedStatus({ identity: { id: identityId } })

  const {
    data,
    isLoading: loading,
    error,
  } = useSWR(identity ? ['useVerifiedStatus', identity.id] : null, fetcher, {
    revalidateOnFocus: false,
    keepPreviousData: true,
    fallbackData: {
      isCrystalUser: !!identity?.isCrystalUser,
      isCrystalManaged: !!identity?.isCrystalManaged,
      isEdited:
        !!identity?.snapshot?.hasBeenEdited ||
        !!identity?.rootSnapshot?.hasBeenEdited,
    },
  })

  return {
    data,
    loading,
    error,
  }
}
