import React from 'react'
import classNamesBind from 'classnames/bind'

import css from './styles.module.scss'

const classNames = classNamesBind.bind(css)

interface OwnProps {
  grey?: boolean
  lightGrey?: boolean
  modalOpen?: boolean | null
  noPadding?: boolean
  overflow?: boolean
  overflowFull?: boolean
  width?: string
  autoWidth?: boolean
  dark?: boolean
  blur?: boolean
  lightClose?: boolean
  noCloseButton?: boolean
  slowAnimation?: boolean
  toggleModal: () => void
  noPrint?: boolean
  children: React.ReactNode
}

interface State {
  fadeOut: boolean
  showModal: boolean
}

class Modal extends React.Component<OwnProps, State> {
  timeout: number | null = null

  // Constructor
  constructor(props: OwnProps) {
    super(props)

    this.state = {
      fadeOut: false,
      showModal: !!this.props.modalOpen,
    }
  }

  componentDidUpdate(prevProps: OwnProps) {
    const { modalOpen, slowAnimation } = this.props

    const modalChanged = modalOpen !== prevProps.modalOpen

    if (modalChanged) {
      this.handleBackgroundChange()

      if (!modalOpen) {
        const timing = slowAnimation ? 1000 : 200
        this.setState({ fadeOut: true })
        this.timeout = window.setTimeout(
          () =>
            this.setState({
              showModal: false,
              fadeOut: false,
            }),
          timing,
        )
      } else this.setState({ showModal: true })
    }
  }

  componentWillUnmount() {
    this.bodyScrollEnabled()
    if (this.timeout !== null) clearTimeout(this.timeout)
  }

  // Render
  render() {
    const {
      toggleModal,
      grey,
      lightGrey,
      noPadding,
      width,
      autoWidth,
      overflow,
      dark,
      blur,
      overflowFull,
      slowAnimation,
      noPrint,
    } = this.props
    const { showModal, fadeOut } = this.state

    const divWidth = width || 800
    let contentStyle = {}

    if (!autoWidth) {
      contentStyle = {
        width: `${divWidth}px`,
      }
    }

    if (!showModal) return null

    return (
      <div
        className={classNames('container', { fadeOut, slowAnimation, noPrint })}
      >
        <div
          className={classNames('background', { dark, blur, slowAnimation })}
          onClick={() => toggleModal()}
        />
        <div
          className={classNames('content', {
            grey,
            lightGrey,
            noPadding,
            overflow,
            overflowFull,
          })}
          style={contentStyle}
        >
          {this.renderCloseIcon()}
          {this.props.children}
        </div>
      </div>
    )
  }

  renderCloseIcon() {
    const { toggleModal, lightClose, noCloseButton } = this.props

    if (noCloseButton) return null
    let src = '/static/share/images/close-thin.svg'
    if (lightClose) src = '/static/share/images/close-thin-white.svg'

    return (
      <img
        src={src}
        className={classNames('back-icon')}
        onClick={() => toggleModal()}
      />
    )
  }

  // Functions
  handleBackgroundChange = () => {
    const { modalOpen } = this.props

    if (modalOpen) {
      document.body.style.overflowY = 'hidden'
    } else {
      document.body.style.overflowY = 'auto'
    }
  }

  bodyScrollEnabled = () => {
    document.body.style.overflowY = 'auto'
  }
}

export default Modal
