interface Identity {
  id: string
}

export type State = {
  data?: string
  loading?: boolean
  error?: any
}

export default function useProfileId({
  identity,
}: {
  identity: Identity
}): State {
  return {
    data: identity?.id?.split(':').pop(),
  }
}
