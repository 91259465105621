import React, { useCallback, useEffect, useMemo, useState } from 'react'
import Image from 'next/image'
import Link from 'next/link'
import searchIcon from '@dashboard/public/static/share/images/search.svg'
import { debounce } from 'throttle-debounce'
import LoadingBar from '@crystal-eyes/components/elements/Loader/LoadingBar'
import staticProfilePhoto from '@dashboard/public/static/share/images/profile-placeholder.svg'
import styles from './SearchBar.module.scss'
import useEnrichedProfiles, {
  FilterAttributes,
} from '@crystal-eyes/hooks/profiles/useEnrichedProfiles'
import VerifiedBadge from '@dashboard/components/VerifiedBadge'
import Icon, { Icons } from '@crystal-eyes/components/elements/Icon/Icon'

interface Identity {
  id: string
  isCoworker?: boolean
  isCandidate?: boolean
  isCrystalManaged?: boolean
  rootSnapshot?: { id: string }
}

const MAX_RESULTS = 6

const PILL_COLORS: { [key: string]: string } = {
  CUSTOMER: '#6c6c6c',
  COWORKER: 'orange',
  TEAMMATE: 'orange',
  CELEBRITY: 'purple',
  CANDIDATE: '#ff00ff',
}

export default function SearchBar() {
  const [expanded, setExpanded] = useState(false)
  const [searchQuery, setSearchQuery] = useState<string>('')

  const [fallbackImages, setFallbackImages] = useState<{
    [key: string]: string
  }>({})

  const [filters, setFilters] = useState<FilterAttributes[]>([])

  const { data, loading: searchLoading } = useEnrichedProfiles({
    filters: filters,
    page: { limit: MAX_RESULTS, offset: 0 },
  })

  const profileLibraryPath = '/app/profile-library'

  const isLoading = searchLoading

  const displayResults = expanded && !isLoading && filters.length > 0

  const doSearch = useCallback(
    debounce(500, (searchFilters: FilterAttributes[]) => {
      setFilters(searchFilters)
    }),
    [setFilters],
  )

  const results = data?.enrichedIdentities?.results

  useEffect(() => {
    let searchFilters: FilterAttributes[]
    if (searchQuery.length === 0) {
      searchFilters = []
    } else if (searchQuery.startsWith('http')) {
      searchFilters = [{ type: 'URL', value: searchQuery }]
    } else {
      searchFilters = [{ type: 'NAME', value: `${searchQuery}%` }]
    }

    doSearch(searchFilters)
  }, [searchQuery, doSearch])

  const isSearching: boolean = useMemo(() => {
    return filters.length > 0
  }, [filters])

  const getProfileLink = (identity: Identity) => {
    const profileId = identity?.rootSnapshot?.id?.replace('snap:', '')

    return `/p/${profileId}`
  }

  const getProfileTag = (identity: Identity) => {
    if (identity.isCandidate) {
      return 'CANDIDATE'
    }

    if (identity.isCoworker) {
      return 'COWORKER'
    }

    if (identity.isCrystalManaged) {
      return 'CELEBRITY'
    }

    return ''
  }

  return (
    <div className={styles.searchArea}>
      <input
        placeholder="View existing profiles"
        value={searchQuery}
        onChange={evt => {
          setSearchQuery(evt.target.value)
        }}
        onFocus={() => setExpanded(true)}
        onBlur={() =>
          setTimeout(() => {
            setExpanded(false)
          }, 500)
        }
      />
      <Image src={searchIcon} width={13} height={13} alt="Search" />

      {displayResults && (
        <div className={styles.resultsArea}>
          {isLoading && (
            <div className={styles.loadingArea}>
              <LoadingBar width="80%" />
            </div>
          )}
          {!isLoading && filters.length === 0 && (
            <div className={styles.ctaText}>
              <span>Enter a name to search for profiles!</span>
            </div>
          )}
          {!isLoading && results?.length === 0 && isSearching && (
            <div className={styles.noResultsText}>
              <div>
                Create profiles using LinkedIn URLs on our add profiles page
              </div>
              <div className={styles.addProfilesLink}>
                <Icon icon={Icons.Link} />
                <Link href={profileLibraryPath}>ADD PROFILES</Link>
              </div>
            </div>
          )}
          {!isLoading &&
            filters.length > 0 &&
            results &&
            results?.length > 0 && (
              <>
                {results?.map((result, index) => (
                  <Link key={result.id} href={getProfileLink(result)}>
                    <div className="result-item">
                      <Image
                        src={
                          fallbackImages[result.id] ||
                          result.personalInfo?.photoUrl ||
                          staticProfilePhoto
                        }
                        width={48}
                        height={48}
                        onError={() => {
                          if (
                            !Object.keys(fallbackImages).includes(result.id)
                          ) {
                            setFallbackImages(curr => ({
                              ...curr,
                              [result.id]: staticProfilePhoto,
                            }))
                          }
                        }}
                        alt={result?.personalInfo?.fullName || ''}
                      />

                      <div className="result-metadata">
                        <div className="name-wrapper">
                          <b>{result?.personalInfo?.fullName}</b>
                          {result.isCrystalUser && <VerifiedBadge />}
                        </div>
                        <div>{result?.personalInfo?.jobTitle}</div>

                        {getProfileTag(result) && (
                          <div className="attrib-pills">
                            <div
                              className="pill"
                              style={{
                                backgroundColor:
                                  PILL_COLORS[getProfileTag(result)],
                              }}
                              key={index.toString()}
                            >
                              {getProfileTag(result)}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </Link>
                ))}
              </>
            )}
        </div>
      )}
    </div>
  )
}
