export const wrapRegexWithBoundary = (regex: RegExp): RegExp => {
  return new RegExp(
    `(?:^|[\\W\\s]|\\b)(${regex.source})(?:$|[\\W\\s]|\\b)`,
    regex.flags,
  )
}

export const getRegexIfValid = (
  pattern: string,
  flag?: string,
): RegExp | null => {
  try {
    const regex = new RegExp(pattern, flag)
    return regex
  } catch (e) {
    /* eslint-disable no-debugger, no-console */
    return null
  }
}

export const isLookBehindSupported = (): boolean => {
  let isLookBehindSupported = false
  try {
    new RegExp('(?<=)')
    isLookBehindSupported = true
  } catch (err) {
    isLookBehindSupported = false
  }
  return isLookBehindSupported
}

export const normalizeText = (text: string): string => {
  return convertHTMLSpaces(enforceStraightQuotes(text))
}

const convertHTMLSpaces = (text: string): string => {
  return text.replace(new RegExp(String.fromCharCode(160), 'g'), ' ')
}

const enforceStraightQuotes = (text: string): string => {
  return text.replace(/[\u2018\u2019]/g, "'").replace(/[\u201C\u201D]/g, '"')
}

export const escapeAllRegexSpecialChars = (text: string): string => {
  return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&')
}

export const enforceSingleSpaces = (text: string): string => {
  return text.replace(/\s\s+/g, ' ')
}

export const capitalizeFirst = (str: string) => {
  return `${str.slice(0, 1).toUpperCase()}${str.slice(1)}`
}

export const startsWithCapital = (str: string): boolean => {
  return str.charAt(0) === str.charAt(0).toUpperCase()
}
