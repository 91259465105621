// @ts-nocheck
import { diag } from '@opentelemetry/api'
import { OTLPExporterError } from '@opentelemetry/otlp-exporter-base'
import {
  DEFAULT_EXPORT_MAX_ATTEMPTS,
  DEFAULT_EXPORT_INITIAL_BACKOFF,
  DEFAULT_EXPORT_BACKOFF_MULTIPLIER,
  DEFAULT_EXPORT_MAX_BACKOFF,
  isExportRetryable,
  parseRetryAfterToMills,
} from '@opentelemetry/otlp-exporter-base/build/src/util'
import { OTLPTraceExporter } from '@opentelemetry/exporter-trace-otlp-http'

const defaultHeaders = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
}

export function patchServiceWorkerExporter(exporter: OTLPTraceExporter) {
  if (
    typeof XMLHttpRequest !== 'undefined' ||
    typeof navigator.sendBeacon === 'function'
  )
    return

  // We are in a Service Worker. They do not have XMLHttpRequest
  // Patch so that the exporter sends with fetch() instead
  // Code pulled from https://github.com/open-telemetry/opentelemetry-js/pull/3542
  exporter.send = function send(
    items: ExportItem[],
    onSuccess: () => void,
    onError: (error: OTLPExporterError) => void,
  ): void {
    if (this._shutdownOnce.isCalled) {
      diag.debug('Shutdown already started. Cannot send objects')
      return
    }
    const serviceRequest = this.convert(items)
    const body = JSON.stringify(serviceRequest)

    const promise = new Promise<void>((resolve, reject) => {
      sendWithFetch(
        body,
        this.url,
        this._headers,
        this.timeoutMillis,
        resolve,
        reject,
      )
    }).then(onSuccess, onError)

    this._sendingPromises.push(promise)
    const popPromise = () => {
      const index = this._sendingPromises.indexOf(promise)
      this._sendingPromises.splice(index, 1)
    }
    promise.then(popPromise, popPromise)
  }
}

// https://github.com/open-telemetry/opentelemetry-js/pull/3542/files#diff-2aacfc9dbdc5a63485aad503e0de3cd057da36801b4d109232cf233fcceddcadR174
/**
 * function to send metrics/spans using browser fetch
 *     used when navigator.sendBeacon and XMLHttpRequest are not available
 * @param body
 * @param url
 * @param headers
 * @param onSuccess
 * @param onError
 */
function sendWithFetch(
  body: string,
  url: string,
  headers: Record<string, string>,
  exporterTimeout: number,
  onSuccess: () => void,
  onError: (error: OTLPExporterError) => void,
): void {
  const controller = new AbortController()
  let cancelRetry: ((e: OTLPExporterError) => void) | undefined
  const exporterTimer = setTimeout(() => {
    controller.abort()
    cancelRetry?.(new OTLPExporterError('Request Timeout'))
  }, exporterTimeout)

  const fetchWithRetry = (
    retries = DEFAULT_EXPORT_MAX_ATTEMPTS,
    minDelay = DEFAULT_EXPORT_INITIAL_BACKOFF,
  ) => {
    return fetch(url, {
      method: 'POST',
      headers: {
        ...defaultHeaders,
        ...headers,
      },
      signal: controller.signal,
      body,
    }).then(
      response => {
        if (response.status >= 200 && response.status <= 299) {
          diag.debug('Request Success')
          return
        } else if (
          response.status &&
          isExportRetryable(response.status) &&
          retries > 0
        ) {
          let retryTime: number
          minDelay = DEFAULT_EXPORT_BACKOFF_MULTIPLIER * minDelay

          // retry after interval specified in Retry-After header
          if (response.headers.has('Retry-After')) {
            retryTime = parseRetryAfterToMills(
              response.headers.get('Retry-After'),
            )
          } else {
            // exponential backoff with jitter
            retryTime = Math.round(
              Math.random() * (DEFAULT_EXPORT_MAX_BACKOFF - minDelay) +
                minDelay,
            )
          }

          return new Promise((resolve, reject) => {
            const retryTimer = setTimeout(() => {
              cancelRetry = undefined
              fetchWithRetry(retries - 1, minDelay).then(resolve, reject)
            }, retryTime)
            cancelRetry = e => {
              clearTimeout(retryTimer)
              reject(e)
            }
          })
        } else {
          return Promise.reject(
            new OTLPExporterError(
              `Failed to export with fetch: (${response.status} ${response.statusText})`,
              response.status,
            ),
          )
        }
      },
      (e: Error) => {
        if (e.name === 'AbortError') {
          return Promise.reject(new OTLPExporterError('Request Timeout'))
        } else {
          return Promise.reject(
            new OTLPExporterError(`Request Fail: ${e.name} ${e.message}`),
          )
        }
      },
    )
  }

  fetchWithRetry()
    .then(
      () => {
        onSuccess()
      },
      e => {
        onError(e)
      },
    )
    .finally(() => clearTimeout(exporterTimer))
}
