import useSWRMutation from 'swr/mutation'
import useAuth from './useAuth'
import { fetchV3ApiAuthed } from '@crystal-eyes/utils/apis/v3Api'

type State = {
  trigger?: any
  isMutating?: any
}

export default function useResendEmailVerification(): State {
  const { data: authData } = useAuth()

  const resendEmail = (path: string) =>
    fetchV3ApiAuthed(authData, path, { method: 'POST' }).then(async res => {
      if (res.ok) {
        const json = await res?.json()
        return json
      }
    })

  const { trigger, isMutating } = useSWRMutation(
    'email_verification/resend',
    resendEmail,
  )

  return {
    trigger,
    isMutating,
  }
}
