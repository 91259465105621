'use client'
import { useEffect, useState } from 'react'
import useStatsigGate from '@crystal-eyes/hooks/third_party/useStatsigGate'
import styles from './PremiumToggle.module.scss'
import Toggle from '@crystal-eyes/components/elements/Toggle'
import useIsFreeUser from '@crystal-eyes/hooks/user/useIsFreeUser'
import useSideNavFlag from '@dashboard/hooks/useSideNavFlag'
import useAuth from '@crystal-eyes/hooks/useAuth'

export default function PremiumToggle() {
  const { authed } = useAuth()
  const { data: isFreeUser } = useIsFreeUser()
  const { data: hasToggleExperience } = useStatsigGate({
    name: 'toggle_experience',
  })
  const { data: isEnabled, update: updateFeatureFlag } = useSideNavFlag()
  const [checked, setChecked] = useState<boolean>(false)
  const hideToggle = (!hasToggleExperience && !isEnabled) || isFreeUser

  useEffect(() => {
    if (authed) fetch('/api/utils/ensureSideNavCookie')
  }, [authed])

  useEffect(() => {
    isEnabled ? setChecked(true) : setChecked(false)
  }, [isEnabled])

  const toggleSideBar = () => {
    fetch('/api/utils/deleteSideNavCookie')
      .then(() => {
        updateFeatureFlag(!checked)
      })
      .catch(() => {
        // eslint-disable-next-line no-console
        console.error('Failed to toggle premium dashboard')
      })
  }

  if (hideToggle) return null
  return (
    <div className={styles.premiumToggle}>
      <Toggle checked={checked} onClick={toggleSideBar} />
      <div className="CE title medium light-gray">Premium dashboard</div>
    </div>
  )
}
