import React, { FC, PropsWithChildren, ReactNode } from 'react'
import styles from './styles.module.scss'
import Header from './components/Header'
import Row from './components/Row'
import Cell from './components/Cell'

export interface OwnProps {
  children: ReactNode
  className?: string
}

const Table: FC<PropsWithChildren<OwnProps>> = ({ children, className }) => {
  return <table className={`${styles.table} ${className}`}>{children}</table>
}

interface TableSubcomponents {
  Header: React.ElementType
  Row: React.ElementType
  Cell: React.ElementType
}

const TableWithSubcomponents = Table as FC<PropsWithChildren<OwnProps>> &
  TableSubcomponents

TableWithSubcomponents.Header = Header
TableWithSubcomponents.Row = Row
TableWithSubcomponents.Cell = Cell

export default TableWithSubcomponents
