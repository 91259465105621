'use client'

import React, { useEffect, useState } from 'react'
import styles from './SideNavigationAuthed.module.scss'
import { CHROME_INSTALL_URL } from '@dashboard/lib/constants'
import Icon, { Icons } from '@crystal-eyes/components/elements/Icon/Icon'
import useMeV2 from '@crystal-eyes/hooks/user/useMeV2'
import NavTopSection from './NavTopSection'
import { usePathname } from 'next/navigation'
import { ProductAccess } from '@crystal-eyes/types'
import { NavSection, NavSectionTitle } from './types'
import NavBottomSection from './NavBottomSection'
import NavLogoSection from './NavLogoSection'
import ProfileSubNav from './secondary_navigation/ProfileSubNav'

export default function SideNavigationAuthed() {
  const { data: me } = useMeV2()
  const pathName = usePathname()
  const miniProfileView = !!pathName?.includes('/p/')
  const miniView = miniProfileView
  const [expanded, setExpanded] = useState<boolean>(true)

  const [activeSection, setActiveSection] = useState<NavSectionTitle | null>(
    null,
  )
  const [mobileOpen, setMobileOpen] = useState<boolean>(false)

  const toggleMobileOpen = () => {
    setMobileOpen(!mobileOpen)
  }

  const toggleActiveSection = (sectionTitle: NavSectionTitle) => {
    setActiveSection(activeSection === sectionTitle ? null : sectionTitle)
  }

  const hasHiring = me?.products?.includes(ProductAccess.Hiring)
  const hasTeams = me?.products?.includes(ProductAccess.Leadership)
  const myVanityUrl = me?.identity?.rootSnapshot?.vanityUrl
  const myProfilePath = myVanityUrl
    ? `/p/${myVanityUrl}`
    : `/p/${me?.identity?.id.replace('ident:', '')}`

  const navSections: NavSection[] = [
    {
      title: 'Install Chrome Extension',
      path: CHROME_INSTALL_URL,
      items: [],
      icon: Icons.Download,
      external: true,
    },
    {
      title: 'Home',
      path: '/app/home',
      items: [],
      icon: Icons.Home,
    },
    {
      title: 'People',
      items: [
        { title: 'My Profile', path: myProfilePath },
        { title: 'Coworkers', path: '/app/leadership/people' },
        { title: 'Profile Library', path: '/app/profile-library' },
      ],
      icon: Icons.Peers,
    },
    {
      title: 'Tools',
      items: [
        { title: 'Playbooks', path: '/app/playbooks' },
        { title: 'Quick Tips', path: '/app/sales/dashboard' },
        { title: 'Writing Assistant', path: '/app/writing-assistant' },
      ],
      icon: Icons.Playbook,
    },

    {
      title: 'Hiring',
      items: [
        { title: 'Candidates', path: '/app/hiring/people' },
        { title: 'Jobs', path: '/app/hiring/jobs' },
      ],
      icon: Icons.Briefcase,
    },
  ]

  const checkPathMatch = () => {
    navSections.forEach(section => {
      if (section.path === pathName) {
        setActiveSection(section.title)
      }
      section.items.forEach(item => {
        if (item.path === pathName) {
          setActiveSection(section.title)
        }
      })
    })
  }

  useEffect(() => {
    if (miniView) {
      setExpanded(false)
    } else {
      checkPathMatch()
    }
  }, [me, pathName])

  return (
    <>
      <nav
        className={`${styles.sideNavBar} ${mobileOpen ? styles.mobileOpen : ''}
        ${expanded ? styles.expanded : ''}
        ${miniView ? styles.miniView : ''}`}
        id="crystal_side_navigation"
        onMouseEnter={() => miniView && setExpanded(true)}
        onMouseLeave={() => miniView && setExpanded(false)}
      >
        <div className={styles.mobileHamburger} onClick={toggleMobileOpen}>
          {mobileOpen ? 'x' : <Icon icon={Icons.Hamburger} />}
        </div>
        <div
          className={`${styles.navigationContainer} ${
            mobileOpen ? styles.mobileOpen : ''
          }`}
        >
          <NavLogoSection expanded={expanded} />

          <div className={styles.navBody}>
            <div className={styles.navSubContainer}>
              <div className={styles.mainNavigation}>
                {navSections.map(section => {
                  if (section.title === 'Hiring' && !hasHiring) return null
                  if (section.title === 'Tools' && hasTeams) {
                    section.items.push({
                      title: 'Teams',
                      path: '/app/my-teams',
                    })
                  }
                  return (
                    <NavTopSection
                      key={section.title}
                      section={section}
                      activeSection={activeSection}
                      toggleActiveSection={toggleActiveSection}
                      expanded={expanded}
                      external={section.external}
                    />
                  )
                })}
              </div>
            </div>
            <NavBottomSection expanded={expanded} />
          </div>
        </div>
      </nav>
      {miniProfileView && <ProfileSubNav />}
    </>
  )
}
