import React, { FC } from 'react'
import ColorIcon from '@dashboard/components/ColorIcon'
import styles from './Pagination.module.scss'

interface Props {
  changePage: (page: number) => void
  currentPage: number
  totalPages: number
}

const Pagination: FC<Props> = ({
  changePage,
  currentPage,
  totalPages,
}: Props) => {
  const pages = [...Array(5)]
    .map((_, i) => currentPage - 2 + i)
    .filter(page => page >= 1 && page <= totalPages)

  const prevDisabled = currentPage === 1
  const nextDisabled = currentPage === totalPages

  const renderPageLink = (num: number) => {
    return (
      <div
        key={num}
        className={`${styles.page} ${
          num === currentPage ? styles.current : ''
        }`}
        onClick={() => changePage(num)}
      >
        {num}
      </div>
    )
  }

  return (
    <div className={styles.pages}>
      <div
        className={`${styles.page} ${styles.arrow} ${styles.prev} ${
          prevDisabled ? styles.disabled : ''
        }`}
        onClick={() => changePage(currentPage - 1)}
      >
        <ColorIcon icon="arrow" width={18} color="#1E749F" />
      </div>
      {currentPage > 3 && (
        <>
          {renderPageLink(1)}
          {currentPage !== 4 && (
            <div className={`${styles.page} ${styles.ellipsis}`}>...</div>
          )}
        </>
      )}
      {pages.map(num => renderPageLink(num))}
      {totalPages > 3 && currentPage < totalPages - 3 && (
        <div className={`${styles.page} ${styles.ellipsis}`}>...</div>
      )}
      {totalPages > 2 &&
        currentPage < totalPages - 2 &&
        renderPageLink(totalPages)}
      <div
        className={`${styles.page} ${styles.arrow} ${styles.next} ${
          nextDisabled ? styles.disabled : ''
        }`}
        onClick={() => changePage(currentPage + 1)}
      >
        <ColorIcon icon="arrow" width={18} color="#1E749F" />
      </div>
    </div>
  )
}

export default Pagination
