'use client'

import { FC } from 'react'
import Drawer from '@crystal-eyes/components/elements/Drawer'
import styles from './CreditLedgerDrawer.module.scss'
import CreditLedger from './CreditLedger'

export interface OwnProps {
  toggleDrawer: () => void
  drawerOpen: boolean
}

const CreditLedgerDrawer: FC<OwnProps> = ({ toggleDrawer, drawerOpen }) => {
  return (
    <Drawer
      className={styles.creditLedgerDrawer}
      isOpen={drawerOpen}
      close={toggleDrawer}
    >
      <CreditLedger />
    </Drawer>
  )
}

export default CreditLedgerDrawer
