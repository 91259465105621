'use client'

// import { useEffect, useState } from 'react'
// import { BrowserAgent } from '@newrelic/browser-agent/loaders/browser-agent'
// import useAuth from '@crystal-eyes/hooks/useAuth'
// import { addInstrumentationAttribute } from '@crystal-eyes/utils/instrumentation'
//
// const initOpts = {
//   distributed_tracing: {
//     enabled: false,
//     cors_use_newrelic_header: true,
//     cors_use_tracecontext_headers: true,
//     allowed_origins: [
//       'https://api.local.crystalknows.com',
//       'https://api.staging.crystalknows.com',
//       'https://api.crystalknows.com',
//       'https://main--crystal-ball-router.apollographos.net',
//       'https://staging--crystal-ball-router.apollographos.net',
//     ],
//   },
//   privacy: { cookies_enabled: true },
//   ajax: { deny_list: ['bam.nr-data.net'] },
// }

export default function NewRelic() {
  // const { data: auth } = useAuth()
  // const [browserAgent, setBrowserAgent] = useState<BrowserAgent | null>(null)
  //
  // useEffect(() => {
  //   if (process.env.APP_ENV == 'production') {
  //     const options = {
  //       info: {
  //         beacon: 'bam.nr-data.net',
  //         errorBeacon: 'bam.nr-data.net',
  //         licenseKey: 'NRJS-9d494fc7b92d3555eb1',
  //         applicationID: '1134396616',
  //         sa: 1,
  //       },
  //       loader_config: {
  //         accountID: '3884513',
  //         trustKey: '3884513',
  //         agentID: '1134396616',
  //         licenseKey: 'NRJS-9d494fc7b92d3555eb1',
  //         applicationID: '1134396616',
  //       },
  //       init: initOpts,
  //     }
  //
  //     setBrowserAgent(new BrowserAgent(options))
  //   } else if (process.env.APP_ENV == 'staging') {
  //     const options = {
  //       info: {
  //         beacon: 'bam.nr-data.net',
  //         errorBeacon: 'bam.nr-data.net',
  //         licenseKey: 'NRJS-9d494fc7b92d3555eb1',
  //         applicationID: '1134396618',
  //         sa: 1,
  //       },
  //       loader_config: {
  //         accountID: '3884513',
  //         trustKey: '3884513',
  //         agentID: '1134396618',
  //         licenseKey: 'NRJS-9d494fc7b92d3555eb1',
  //         applicationID: '1134396618',
  //       },
  //       init: initOpts,
  //     }
  //
  //     setBrowserAgent(new BrowserAgent(options))
  //   } else if (process.env.APP_ENV == 'development') {
  //     const options = {
  //       info: {
  //         beacon: 'bam.nr-data.net',
  //         errorBeacon: 'bam.nr-data.net',
  //         licenseKey: 'NRJS-9d494fc7b92d3555eb1',
  //         applicationID: '1134396628',
  //         sa: 1,
  //       },
  //       loader_config: {
  //         accountID: '3884513',
  //         trustKey: '3884513',
  //         agentID: '1134396628',
  //         licenseKey: 'NRJS-9d494fc7b92d3555eb1',
  //         applicationID: '1134396628',
  //       },
  //       init: initOpts,
  //     }
  //
  //     setBrowserAgent(new BrowserAgent(options))
  //   }
  // }, [])
  //
  // useEffect(() => {
  //   if (browserAgent && auth?.authed) {
  //     browserAgent.setCustomAttribute(
  //       'user_name',
  //       auth?.jwtPayload?.friendly_name || null,
  //     )
  //     browserAgent.setCustomAttribute(
  //       'user_org_id',
  //       auth?.jwtPayload?.org_id || null,
  //     )
  //     browserAgent.setCustomAttribute(
  //       'impersonated_by',
  //       auth?.jwtPayload?.impersonated_by || null,
  //     )
  //
  //     addInstrumentationAttribute(
  //       'user_name',
  //       auth?.jwtPayload?.friendly_name || null,
  //     )
  //     addInstrumentationAttribute(
  //       'user_org_id',
  //       auth?.jwtPayload?.org_id || null,
  //     )
  //     addInstrumentationAttribute(
  //       'impersonated_by',
  //       auth?.jwtPayload?.impersonated_by || null,
  //     )
  //   }
  // }, [browserAgent, auth])

  return null
}
