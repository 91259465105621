import React from 'react'
import styles from './IdentityIcon.module.scss'
import Image from 'next/image'
import { DiscType } from '@crystal-eyes/types'

interface Identity {
  id: string
  personalInfo?: {fullName?: string, firstName?: string, lastName?: string, photoUrl?: string},
  snapshot?: {personality?: {discType: DiscType}}
}

type Props = {
  identity: Identity
}

export default function IdentityIcon({ identity }: Props) {
  let name = identity.personalInfo?.fullName
  if (!name && identity.personalInfo?.firstName) {
    name = `${identity.personalInfo?.firstName} ${identity.personalInfo?.lastName}`
  } else if (!name) {
    name = '?'
  }

  const discType = identity.snapshot?.personality?.discType

  if (identity.personalInfo?.photoUrl) {
    return (
      <Image
        className={styles.photoIcon}
        src={identity.personalInfo?.photoUrl}
        alt={identity.personalInfo?.fullName || 'Crystal User'}
        title={identity.personalInfo?.fullName}
        width={32}
        height={32}
      />
    )
  } else {
    let initials = ''

    const splitName = name.split(' ')
    if (
      splitName.length >= 2 &&
      splitName[0].length > 0 &&
      splitName[1].length > 0
    )
      initials = `${splitName[0][0]}${splitName[1][0]}`
    else if (splitName.length >= 1 && splitName[0].length > 0)
      initials = `${splitName[0][0]}`
    else initials = '?'

    return (
      <div className={`${styles.initialsIcon} ${styles[discType || 'none']}`}>
        {initials}
      </div>
    )
  }
}
