import useSWR from 'swr'
import useAuth from '@crystal-eyes/hooks/useAuth'
import { fetchV3ApiAuthed } from '@crystal-eyes/utils/apis/v3Api'

type State = {
  data?: any
  error?: any
  loading: boolean
}

export default function useMe(): State {
  const { data: auth } = useAuth()

  const fetcher = (__key: string) =>
    fetchV3ApiAuthed(auth, 'me', { method: 'GET' }).then(
      async res => (await res.json()).data,
    )

  const { data, isLoading, error, mutate } = useSWR(
    auth?.authed ? 'global.user.me' : null,
    fetcher,
    {
      keepPreviousData: true,
      revalidateOnFocus: false,
      refreshInterval: 1000 * 60 * 60,
    },
  )

  return {
    data,
    loading: isLoading,
    error,
  }
}
