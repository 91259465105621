import { Lato, Merriweather } from 'next/font/google'

const lato = Lato({
  weight: ['300', '400', '700', '900'],
  subsets: ['latin'],
  variable: '--font-lato',
})

const merriweather = Merriweather({
  weight: ['300'],
  subsets: ['latin'],
  variable: '--font-merriweather',
})

export const fontVariables = `
  ${merriweather.variable}
  ${lato.variable}
`
