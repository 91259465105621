import Axios from 'axios'
import { v3ApiUrl } from '@crystal-eyes/config'
import { appName, localAuthToken, sessionToken } from './user'
import md5 from 'md5'

export interface AuthedHeaders {
  authToken: string
  sessionToken: string
  appName?: string
}

export const PublicApi = () =>
  Axios.create({
    baseURL: `${v3ApiUrl}/v3`,
  })

export const Api = (headers?: AuthedHeaders) =>
  Axios.create({
    baseURL: `${v3ApiUrl}/v3`,
    headers: {
      Authorization: `Bearer ${headers?.authToken ?? localAuthToken()}`,
      Session: headers?.sessionToken ?? sessionToken(),
      App: headers?.appName ?? appName(),
    },
  })

export const convertDataTags = (tags: string[]): string => {
  return md5(tags.slice().sort().join(''))
}

export const defaultDataTags = [
  ['profile', 'overview'],
  ['profile', 'quality'],
  ['behavior', 'overview'],
  ['behavior', 'phrase'],
  ['motivation', 'phrase'],
  ['drainer', 'quality'],
]
