import { useMemo, createContext, useContext, useCallback } from 'react'
import { Notification } from '@crystal-eyes/types'
import useSWR from 'swr'
import useAuth, { AuthDetails } from '@crystal-eyes/hooks/useAuth'
import { queryWithAuth, gql } from '@crystal-eyes/utils/apis/graphqlApi'

export const NOTIFS_QUERY = gql`
  query GetNotifications {
    notifications {
      id
      type
      actor {
        rootSnapshot {
          id
        }

        personalInfo {
          fullName
          photoUrl
        }
      }
    }
  }
`

export const MARK_NOTIFICATIONS_READ = gql`
  mutation MarkNotificationsRead($ids: [Guid!]) {
    markNotificationsRead(ids: $ids) {
      id
      type
    }
  }
`

export type State = {
  data: { notifications: Notification[] } | undefined
  loading: boolean
  error: any
  markAsRead: (id: string) => void
  markAllAsRead: () => void
  refresh: () => void
}

export const NotificationsContext = createContext<State | null>(null)

export default function useNotifications(): State {
  const { data: auth } = useAuth()
  const existingCtx = useContext(NotificationsContext)
  if (existingCtx) return existingCtx

  const fetcher = ([auth, query]: [AuthDetails, string]) =>
    queryWithAuth(auth, query)

  const {
    data: notifData,
    isLoading: loading,
    error,
    mutate,
  } = useSWR([auth, NOTIFS_QUERY], fetcher, {
    keepPreviousData: true,
  })

  const refreshNotifs = useCallback(() => mutate(), [mutate])

  const markNotifsRead = useCallback(
    (variables: object) => {
      queryWithAuth(auth, MARK_NOTIFICATIONS_READ, variables).then(() =>
        mutate(),
      )
    },
    [mutate, auth],
  )

  const markAllAsRead = useCallback(() => {
    if (!notifData?.notifications) return

    const ids = notifData.notifications.map((notif: Notification) => notif.id)
    markNotifsRead({ ids: ids })
  }, [markNotifsRead, notifData])

  const markAsRead = useCallback(
    (id: string) => {
      markNotifsRead({ ids: [id] })
    },
    [markNotifsRead],
  )

  const parsedData = useMemo(() => {
    if (!notifData?.notifications) return undefined

    return {
      notifications: notifData.notifications,
    }
  }, [notifData])

  if (existingCtx) return existingCtx

  return {
    data: parsedData,
    loading,
    error,
    markAllAsRead,
    markAsRead,
    refresh: refreshNotifs,
  }
}
