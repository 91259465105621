import useSWR from 'swr'
import Statsig, { StatsigUser } from 'statsig-js'
import useAuth from '@crystal-eyes/hooks/useAuth'
import logger from '@crystal-eyes/utils/logger'

type Options = {
  name: string
  allowUnauthed?: boolean
}

export function useInitStatsig() {
  const { data: auth, loading: authLoading } = useAuth()

  const { data: initializedForUser, isLoading } = useSWR(
    !authLoading
      ? ['useStatsigGate.initializedFor', auth?.jwtPayload?.friendly_name]
      : null,
    () => {
      const userID = auth?.jwtPayload?.uuid || ''
      const email = auth?.jwtPayload?.friendly_name || ''
      const orgID = auth?.jwtPayload?.org_id
      const user: StatsigUser = {
        userID: userID,
        email,
        custom: { orgID },
      }

      logger.debug(
        'Initialized Statsig',
        auth?.jwtPayload?.friendly_name,
        `User: ${userID}`,
        `Org: ${orgID}`,
      )

      if (Statsig.initializeCalled()) {
        return Statsig.updateUser(user).then(() => user)
      } else {
        return Statsig.initialize(
          'client-6SPZqTET8yrJpLelMZzWcQ1GIv2Z50PIiNfgapEExZa',
          user,
          {
            environment: {
              tier: process.env['APP_ENV'] || process.env['NODE_ENV'],
            },
          },
        ).then(() => user)
      }
    },
    {
      keepPreviousData: true,
      revalidateOnFocus: false,
      revalidateIfStale: false,
    },
  )

  return { initializedForUser, isLoading }
}

export default function useStatsigGate({ name, allowUnauthed }: Options) {
  const { initializedForUser } = useInitStatsig()

  const fetcher = ([__key, gateName, _authedUser]: [
    string,
    string,
    string,
  ]) => {
    const gateOpen = Statsig.checkGate(gateName)
    logger.info('Statsig Gate Check', gateName, gateOpen)

    return { gateOpen }
  }

  const { data, isLoading, error } = useSWR(
    initializedForUser?.email || allowUnauthed
      ? ['useStatsigGate', name, initializedForUser?.email]
      : null,
    fetcher,
    {
      revalidateOnFocus: false,
      keepPreviousData: true,
      refreshInterval: 1000 * 60 * 60 * 12,
    },
  )

  return {
    data: data?.gateOpen,
    loading: isLoading,
    error,
  }
}
