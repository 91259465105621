import { DiscType } from './types'

export type Archetype = { title: string; descriptors: string[] }

export const directives: { [key in DiscType]: string } = {
  [DiscType.DI]: 'Casual',
  [DiscType.Di]: 'Brief',
  [DiscType.D]: 'Direct',
  [DiscType.Dc]: 'Concise',
  [DiscType.CD]: 'Objective',
  [DiscType.Cd]: 'Accurate',
  [DiscType.C]: 'Detailed',
  [DiscType.Cs]: 'Precise',
  [DiscType.SC]: 'Formal',
  [DiscType.Sc]: 'Polite',
  [DiscType.S]: 'Warm',
  [DiscType.Si]: 'Polite',
  [DiscType.IS]: 'Friendly',
  [DiscType.Is]: 'Expressive',
  [DiscType.I]: 'Engaging',
  [DiscType.Id]: 'Compelling',
}

export const archetypes: { [key in DiscType | 'empty']: Archetype } = {
  [DiscType.DI]: {
    title: 'Initiator',
    descriptors: ['Spontaneous', 'Opinionated', 'Ambitious'],
  },
  [DiscType.Di]: {
    title: 'Driver',
    descriptors: ['Direct', 'Persuasive', 'Decisive'],
  },
  [DiscType.D]: {
    title: 'Captain',
    descriptors: ['Assertive', 'Confident', 'Hard-working'],
  },
  [DiscType.Dc]: {
    title: 'Architect',
    descriptors: ['Competitive', 'Vigorous', 'Efficient'],
  },
  [DiscType.CD]: {
    title: 'Questioner',
    descriptors: ['Persistent', 'Methodical', 'Independent'],
  },
  [DiscType.Cd]: {
    title: 'Skeptic',
    descriptors: ['Skeptical', 'Pragmatic', 'Accurate'],
  },
  [DiscType.C]: {
    title: 'Analyst',
    descriptors: ['Objective', 'Collected', 'Reserved'],
  },
  [DiscType.Cs]: {
    title: 'Editor',
    descriptors: ['Meticulous', 'Calm', 'Purposeful'],
  },
  [DiscType.SC]: {
    title: 'Stabilizer',
    descriptors: ['Perceptive', 'Consistent', 'Organized'],
  },
  [DiscType.Sc]: {
    title: 'Planner',
    descriptors: ['Patient', 'Thoughtful', 'Soft-spoken'],
  },
  [DiscType.S]: {
    title: 'Supporter',
    descriptors: ['Steady', 'Accepting', 'Agreeable'],
  },
  [DiscType.Si]: {
    title: 'Counselor',
    descriptors: ['Accomodating', 'Diplomatic', 'Reassuring'],
  },
  [DiscType.IS]: {
    title: 'Harmonizer',
    descriptors: ['Warm', 'Cooperative', 'Creative'],
  },
  [DiscType.Is]: {
    title: 'Encourager',
    descriptors: ['Optimistic', 'Open-minded', 'Inclusive'],
  },
  [DiscType.I]: {
    title: 'Motivator',
    descriptors: ['Enthusiastic', 'Casual', 'Talkative'],
  },
  [DiscType.Id]: {
    title: 'Influencer',
    descriptors: ['Adventurous', 'Visionary', 'Outgoing'],
  },
  empty: {
    title: 'Unknown',
    descriptors: [],
  },
}
