import React, { createRef } from 'react'
import classNamesBind from 'classnames/bind'
import css from './styles.module.scss'
const classNames = classNamesBind.bind(css)
const DEFAULT_DIRECTION: OverlayDirection = 'topRight'
type OverlayDirection =
  | 'topRight'
  | 'topLeft'
  | 'bottomRight'
  | 'bottomLeft'
  | 'bottom'
  | 'top'

interface OwnProps {
  overlayContent: React.ReactNode
  preferredDirection?: OverlayDirection
  withTransform?: boolean
  overlayGap?: number
  noHover?: boolean
  flex?: boolean
  fullWidth?: boolean
  containerStyles?: React.CSSProperties
  children: React.ReactNode
}

interface State {
  top: number
  left: number
}

export default class ReactiveOverlayFixed extends React.Component<
  OwnProps,
  State
> {
  containerRef = createRef<HTMLDivElement>()
  overlayRef = createRef<HTMLDivElement>()

  constructor(props: OwnProps) {
    super(props)

    this.state = {
      top: 0,
      left: 0,
    }
  }
  render() {
    const { children, containerStyles, noHover, flex, fullWidth } = this.props
    const style: React.CSSProperties = containerStyles || {}
    return (
      <div
        className={classNames('container', {
          noHover,
          hover: !noHover,
          flex,
          fullWidth,
        })}
        ref={this.containerRef}
        onMouseOver={this.updatePosition}
        style={style}
      >
        {children}
        {this.renderOverlay()}
      </div>
    )
  }

  // Partials
  renderOverlay() {
    const { overlayContent, withTransform, noHover } = this.props

    return (
      <div
        style={this.overlayStyles()}
        className={classNames(
          'overlay',
          { withTransform, noHover },
          this.overlayDirection(),
        )}
        ref={this.overlayRef}
      >
        {overlayContent}
      </div>
    )
  }

  // Functions
  overlayDirection(): OverlayDirection {
    const { preferredDirection } = this.props

    return preferredDirection || DEFAULT_DIRECTION
  }

  overlayStyles(): React.CSSProperties {
    const { top, left } = this.state

    return {
      top: `${top}px`,
      left: `${left}px`,
    }
  }

  calculateTop(
    containerRect: DOMRect,
    overlayRect: DOMRect,
    direction: OverlayDirection,
  ): number {
    let top = containerRect.top
    const overlayHeight = overlayRect.height * 1.2

    switch (direction) {
      case 'topRight':
      case 'topLeft':
      case 'top': {
        top = containerRect.top - overlayHeight - this.overlayGap()

        if (top < 0) {
          top = containerRect.top + containerRect.height
        }

        break
      }
      case 'bottomRight':
      case 'bottomLeft':
      case 'bottom': {
        top = containerRect.bottom + this.overlayGap()

        if (top + overlayHeight > document.body.scrollHeight) {
          top = containerRect.top + overlayHeight
        }

        break
      }
    }

    return top
  }

  calculateLeft(
    containerRect: DOMRect,
    overlayRect: DOMRect,
    direction: OverlayDirection,
  ): number {
    let left = 0
    const overlayWidth = overlayRect.width * 1.2

    switch (direction) {
      case 'topRight':
      case 'bottomRight': {
        left = containerRect.right + this.overlayGap()
        const right = left + overlayWidth

        if (right > window.innerWidth) {
          left -= right - window.innerWidth + 32
        }

        break
      }
      case 'topLeft':
      case 'bottomLeft': {
        left = containerRect.left - overlayWidth - this.overlayGap()

        if (left < 0) {
          left += -left + 32
        }

        break
      }
      case 'bottom':
      case 'top': {
        left = containerRect.left + containerRect.width / 2 - overlayWidth / 2

        if (left < 0) left += -left + 32
        else if (left + overlayWidth > window.innerWidth) {
          left -= left + overlayWidth - window.innerWidth + 32
        }
        break
      }
    }

    return left
  }

  updatePosition = () => {
    const containerEl = this.containerRef.current
    const overlayEl = this.overlayRef.current
    const direction = this.overlayDirection()

    if (!overlayEl || !containerEl) return

    const containerRect = containerEl.getBoundingClientRect()
    const overlayRect = overlayEl.getBoundingClientRect()

    this.setState({
      top: this.calculateTop(containerRect, overlayRect, direction),
      left: this.calculateLeft(containerRect, overlayRect, direction),
    })
  }

  overlayGap() {
    const { overlayGap } = this.props

    return overlayGap ?? 0
  }
}
