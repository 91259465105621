// https://opentelemetry.io/docs/demo/services/frontend/
import {
  CompositePropagator,
  W3CBaggagePropagator,
  W3CTraceContextPropagator,
} from '@opentelemetry/core'
import { WebTracerProvider } from '@opentelemetry/sdk-trace-web'
import { BatchSpanProcessor } from '@opentelemetry/sdk-trace-base'
import {
  Instrumentation,
  registerInstrumentations,
} from '@opentelemetry/instrumentation'
import { XMLHttpRequestInstrumentation } from '@opentelemetry/instrumentation-xml-http-request'
import { FetchInstrumentation } from '@opentelemetry/instrumentation-fetch'
import { Resource } from '@opentelemetry/resources'
import {
  SEMRESATTRS_SERVICE_NAME,
  SEMRESATTRS_DEPLOYMENT_ENVIRONMENT,
  SEMRESATTRS_DEVICE_ID,
  SEMRESATTRS_SERVICE_VERSION,
} from '@opentelemetry/semantic-conventions'
import { OTLPTraceExporter } from '@opentelemetry/exporter-trace-otlp-http'
import { v4 as uuidv4 } from 'uuid'
import { ContextManager } from '@opentelemetry/api'
import { patchServiceWorkerExporter } from '@crystal-eyes/utils/customizeOtlpExporter'

type Opts = {
  ignoreNetworkEvents?: boolean
  disableXmlHttpTracing?: boolean
  contextManager?: ContextManager
  resource?: { version?: string }
}

export let provider: WebTracerProvider | undefined
const FrontendTracer = async (
  serviceName: string,
  opts: Opts = {},
): Promise<WebTracerProvider | null> => {
  if (provider) return provider

  provider = new WebTracerProvider({
    resource: new Resource({
      [SEMRESATTRS_SERVICE_NAME]: serviceName,
      [SEMRESATTRS_DEPLOYMENT_ENVIRONMENT]: process.env.NODE_ENV,
      [SEMRESATTRS_DEVICE_ID]: uuidv4(),
      [SEMRESATTRS_SERVICE_VERSION]: opts?.resource?.version || 'unknown',
    }),
  })

  if (process.env.NODE_ENV !== 'test') {
    const exporter = new OTLPTraceExporter({
      url: 'https://otel.crystalknows.com:443/v1/traces',
      headers: {},
    })

    patchServiceWorkerExporter(exporter)

    provider.addSpanProcessor(
      new BatchSpanProcessor(exporter, {
        // The maximum queue size. After the size is reached spans are dropped.
        maxQueueSize: 100,
        // The maximum batch size of every export. It must be smaller or equal to maxQueueSize.
        maxExportBatchSize: 10,
        // The interval between two consecutive exports
        scheduledDelayMillis: 2000,
        // How long the export can run before it is cancelled
        exportTimeoutMillis: 30000,
      }),
    )
  }

  let contextManager: ContextManager
  if (opts.contextManager) {
    contextManager = opts.contextManager
  } else {
    const { ZoneContextManager } = await import('@opentelemetry/context-zone')
    contextManager = new ZoneContextManager()
  }

  provider.register({
    contextManager,
    propagator: new CompositePropagator({
      propagators: [
        new W3CBaggagePropagator(),
        new W3CTraceContextPropagator(),
      ],
    }),
  })

  const networkTracingConfig = {
    propagateTraceHeaderCorsUrls: /crystalknows/,
    ignoreNetworkEvents: opts.ignoreNetworkEvents,
    clearTimingResources: true,
  }

  const instrumentations: Instrumentation[] = []
  if (!opts.disableXmlHttpTracing) {
    instrumentations.push(
      new XMLHttpRequestInstrumentation({
        ...networkTracingConfig,
        applyCustomAttributesOnSpan: (span, _req) => {
          if (typeof window !== 'undefined')
            span.setAttribute('web.url', window?.location?.href)
        },
      }),
    )
  }

  instrumentations.push(
    new FetchInstrumentation({
      ...networkTracingConfig,
      applyCustomAttributesOnSpan: (span, _req, _result) => {
        if (typeof window !== 'undefined')
          span.setAttribute('web.url', window?.location?.href)
      },
    }),
  )

  registerInstrumentations({
    tracerProvider: provider,
    instrumentations: instrumentations,
  })

  return provider
}

export default FrontendTracer
