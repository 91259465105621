import { apiCallback, apiObject, apiOptions } from 'rudder-sdk-js'

interface RudderAnalyticsV2 {
  identify: (
    userId: string,
    traits?: apiObject,
    options?: apiOptions,
    callback?: apiCallback,
  ) => void
  track: (
    event: string,
    properties?: apiObject,
    options?: apiOptions,
    callback?: apiCallback,
  ) => void
}

export const track = (...args: Parameters<RudderAnalyticsV2['track']>) => {
  if (process.env.RUNTIME_ENV === 'server' || typeof window === 'undefined') {
    return
  }

  import('rudder-sdk-js').then(rudder => rudder.track(...args))
}

export function identify(...args: Parameters<RudderAnalyticsV2['identify']>) {
  if (process.env.RUNTIME_ENV === 'server' || typeof window === 'undefined') {
    return
  }

  import('rudder-sdk-js').then(rudder => rudder.identify(...args))
}
