import getIsOwnProfile from '@crystal-eyes/data/profiles/getIsOwnProfile'
import useSWR from 'swr'

interface Identity {
  id: string
}

type Options = {
  identity?: Identity
}

type State = {
  data?: boolean
  loading?: boolean
  error?: any
}

export default function useIsOwnProfile({ identity }: Options): State {
  const {
    data,
    isLoading: loading,
    error: error,
  } = useSWR(identity ? ['useIsOwnProfile', identity?.id] : null, () =>
    getIsOwnProfile({ identity: identity! }),
  )

  return {
    data,
    loading,
    error,
  }
}
