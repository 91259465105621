import React, { FC } from 'react'
import styles from '../styles.module.scss'
import { SubComponentProps } from '../types'

const Row: FC<SubComponentProps> = ({ className, children }) => {
  return (
    <tr className={`${styles.tableRow} ${className ? className : ''}`}>
      {children}
    </tr>
  )
}

export default Row
