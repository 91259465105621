interface RudderAnalytics {
  load: (
    writeKey: string,
    dataPlanUrl: string,
    options?: Record<string, unknown>,
  ) => void
  identify: (
    userId: string,
    traits?: Record<string, unknown>,
    options?: Record<string, unknown>,
    callback?: () => void,
  ) => void
  /**
   * This method allows you to track any actions that your users might perform. Each of these actions is commonly referred to as an event.
   */
  track: (
    event: string,
    properties?: Record<string, unknown>,
    options?: Record<string, unknown>,
    callback?: () => void,
  ) => void
  page: (
    category?: string,
    name?: string,
    properties?: Record<string, unknown>,
    callback?: () => void,
  ) => void
  group: (...args: unknown[]) => void
  getAnonymousId: (...args: unknown[]) => void
  ready: (...args: unknown[]) => void
  reset: (...args: unknown[]) => void
  setAnonymousId: (...args: unknown[]) => void
}

function rudderAnalytics(): RudderAnalytics {
  type WindowWithRS = Window & { rudderanalytics: RudderAnalytics }

  const windowWithRS = window as unknown as WindowWithRS

  return windowWithRS.rudderanalytics
}

export function rsEvent(...args: Parameters<RudderAnalytics['track']>) {
  rudderAnalytics().track(...args)
}

export function rsPage(...args: Parameters<RudderAnalytics['page']>) {
  rudderAnalytics().page(...args)
}

export function rsIdentify(...args: Parameters<RudderAnalytics['identify']>) {
  rudderAnalytics().identify(...args)
}

export function rsGroup(...args: Parameters<RudderAnalytics['group']>) {
  rudderAnalytics().group(...args)
}

export function pathnameHelper(pathname: string) {
  const guid =
    /[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}/gi
  const receiptShowPage = /\/app\/receipt\/.+/
  const profileVanityPage = /\/p\/.+/
  const myersBriggs = /\/myers-briggs\/.+\/famous-people/
  const enneagram = /\/enneagram\/.+\/famous-people/
  const disc = /\/disc\/.+\/famous-people/
  const comparison = /\/comparison\/famous-people\/.+/
  const jobTitlePersonality = /\/personality-types\/.+/
  const celebProfile = /\/personality\/.+/

  return pathname
    .replace(guid, 'guid')
    .replace(receiptShowPage, '/app/receipt/id')
    .replace(profileVanityPage, '/p/guid')
    .replace(myersBriggs, '/myers-briggs/type/famous-people')
    .replace(enneagram, '/enneagram/type/famous-people')
    .replace(disc, '/disc/type/famous-people')
    .replace(comparison, '/comparison/famous-people/names')
    .replace(jobTitlePersonality, '/personality-types/job-title')
    .replace(celebProfile, '/personality/name')
}
