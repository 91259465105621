import useSWR from 'swr'
import useAuth from '@crystal-eyes/hooks/useAuth'
import { queryWithAuth, gql } from '@crystal-eyes/utils/apis/graphqlApi'

interface Identity {
  id: string
}

type Options = {
  identity?: Identity
}

export type State = {
  data?: { isCoworker: boolean; isCandidate: boolean }
  loading?: boolean
  error?: any
}

const RELATIONSHIP_QUERY = gql`
  query UseRelationship($id: Guid!) {
    identity(id: $id) {
      id
      isCoworker
      isCandidate
    }
  }
`

export default function useRelationship({ identity }: Options): State {
  const { data: auth } = useAuth()

  const fetcher = ([__key, identityId]: [string, string]) =>
    queryWithAuth(auth, RELATIONSHIP_QUERY, { id: identityId })
  const query = auth && identity ? ['useRelationship', identity.id] : null

  const {
    data: queryData,
    isLoading: loading,
    error,
  } = useSWR(query, fetcher, {
    revalidateOnFocus: false,
    keepPreviousData: true,
    fallbackData: { identity },
  })

  return {
    data: queryData?.identity,
    loading,
    error,
  }
}
