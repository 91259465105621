'use client'

import React, { useState } from 'react'
import styles from './NavBottomSection.module.scss'
import Link from 'next/link'
import Icon, { Icons } from '@crystal-eyes/components/elements/Icon/Icon'
import useMeV2 from '@crystal-eyes/hooks/user/useMeV2'
import useUsageStats from '@crystal-eyes/hooks/user/useUsageStats'
import UsageToken from './UsageToken'
import CreditLedgerDrawer from './CreditLedgerDrawer'
import MiniUtilityNav from './MiniUtlityNav'
import AvatarCircle from '@crystal-eyes/components/elements/Avatar/AvatarCircle'

export default function NavBottomSection({ expanded }: { expanded: boolean }) {
  const [isUtlityNavOpen, setIsUtilityNavOpen] = useState(false)
  const { data: me } = useMeV2()
  const { data: usageStats } = useUsageStats()
  const [creditLedgerOpen, setCreditLedgerOpen] = useState<boolean>(false)

  const isAdmin = me?.isOrgAdmin
  const personalSettingsPath = '/app/account/settings'
  const allowance = usageStats?.allowance
  const usageUsed = usageStats?.usage
  const usageRemaining = allowance && usageUsed ? allowance - usageUsed : null

  const toggleCreditLedgerDrawer = () => {
    if (!isAdmin) return
    setCreditLedgerOpen(!creditLedgerOpen)
  }

  return (
    <div
      className={`${styles.bottomContainer} ${expanded ? styles.expanded : ''}`}
    >
      {me?.identity && (
        <div className={styles.profile}>
          <AvatarCircle
            r={17}
            name={me?.identity?.personalInfo?.fullName}
            discType={me?.identity?.snapshot?.personality?.discType as string}
            photoUrl={me?.identity?.personalInfo?.photoUrl}
          />
          <Link
            href={personalSettingsPath}
            className={`CE title white medium ${styles.profileLink}`}
          >
            {me.identity.personalInfo?.fullName}
          </Link>
        </div>
      )}
      <div>
        {usageRemaining && (
          <>
            <UsageToken
              credits={usageRemaining}
              onClick={isAdmin ? toggleCreditLedgerDrawer : undefined}
              expanded={expanded}
            />
            {creditLedgerOpen && (
              <CreditLedgerDrawer
                drawerOpen={creditLedgerOpen}
                toggleDrawer={toggleCreditLedgerDrawer}
              />
            )}
          </>
        )}
      </div>

      <div className={styles.moreOptionsLink}>
        <MiniUtilityNav
          open={isUtlityNavOpen}
          setOpen={setIsUtilityNavOpen}
          expanded={expanded}
        />
        <span
          className={styles.hamburgerIconWrapper}
          onClick={() => setIsUtilityNavOpen(!isUtlityNavOpen)}
        >
          <Icon icon={Icons.Hamburger} className={styles.hamburgerIcon} />
        </span>
        <button onClick={() => setIsUtilityNavOpen(!isUtlityNavOpen)}>
          More options
        </button>
      </div>
      <div>
        <Link href="/app/logout" className={styles.logoutLink} prefetch={false}>
          <Icon icon={Icons.Download} className={styles.downloadIcon} />
          Logout
        </Link>
      </div>
    </div>
  )
}
