import { createContext, useContext } from 'react'

type Identity = {
  id: string
  rootSnapshot?: {id: string}
}

export type State = {
  data?: { identity: Identity }
  loading?: boolean
  error?: any
}

export const IdentityContext = createContext<State | null>(null)

export default function useIdentity(): State {
  const resp = useContext(IdentityContext)

  return resp
    ? resp
    : {
        data: undefined,
        loading: false,
        error: 'No identity context given',
      }
}
