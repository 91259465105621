import { useMemo } from 'react'
import useSWR from 'swr'

type PrintSections = { [key: string]: boolean }
type State = {
  data?: {
    print: boolean
  }
  modalState: { show: boolean; setShow: (value: boolean) => void }
  printState: {
    state: PrintSections | undefined
    setState: (newValue: PrintSections) => void
  }
}

type Options = {
  cardId?: string
}

let sharedPrintSections: undefined | PrintSections
let sharedShowModal: boolean = false
export default function usePrint({ cardId }: Options = {}): State {
  const { data: showModal, mutate: mutateShowModal } = useSWR(
    'usePrint.showModal',
    () => sharedShowModal,
    { keepPreviousData: true, revalidateOnFocus: false },
  )

  const modalState = useMemo(() => {
    const setShowModal = (newValue: boolean) => {
      sharedShowModal = newValue
      mutateShowModal(sharedShowModal)
    }

    return { show: !!showModal, setShow: setShowModal }
  }, [showModal, mutateShowModal])

  const { data: printSections, mutate: mutatePrintSections } = useSWR(
    'usePrint.printSections',
    () => sharedPrintSections,
    {
      keepPreviousData: true,
    },
  )

  const printState = useMemo(() => {
    const setPrintState = (newValue: PrintSections) => {
      sharedPrintSections = newValue
      mutatePrintSections(sharedPrintSections)
    }

    return { state: printSections, setState: setPrintState }
  }, [printSections, mutatePrintSections])

  const fetcher = ([__key, cardId, printSections]: [
    string,
    string,
    PrintSections,
  ]) => {
    return {
      print:
        printSections && cardId in printSections
          ? !!printSections[cardId]
          : true,
    }
  }

  const { data } = useSWR(['usePrint', cardId, printSections], fetcher)

  return {
    data,
    modalState,
    printState,
  }
}
