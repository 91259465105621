import React, { useCallback } from 'react'
import { Notification, NotificationType } from '@crystal-eyes/types'
import Image from 'next/image'
import Link from 'next/link'
import LoadingBar from '@crystal-eyes/components/elements/Loader/LoadingBar'
import staticProfilePhoto from '@dashboard/public/static/share/images/profile-placeholder.svg'
import styles from './Notifications.module.scss'
import useIdentity from '@crystal-eyes/hooks/contextual/useIdentity'
import useNotifications from '@crystal-eyes/hooks/contextual/useNotifications'
export const TYPES_TO_TEXT: { [key in NotificationType]: string } = {
  [NotificationType.InvitationAccepted]: 'accepted your invitation',
  [NotificationType.EndorsementReceived]: 'endorsed your profile',
  [NotificationType.Other]: 'created a new playbook',
  [NotificationType.ApprovalReceived]: 'endorsed your profile',
  [NotificationType.SkillAssessmentTaken]:
    'completed your relational proficiency test.',
}

export default function Notifications() {
  const {
    data: notifData,
    loading: isLoading,
    markAllAsRead,
    markAsRead,
  } = useNotifications()
  const notifications = notifData?.notifications || []

  const { data: identData } = useIdentity()
  const profileId = identData?.identity?.rootSnapshot?.id?.replace('snap:', '')

  const actorContent = useCallback((notif: Notification) => {
    let content
    if (notif.actor?.personalInfo) {
      const personalInfo = notif.actor.personalInfo
      const fullName = personalInfo.fullName
        ? personalInfo.fullName
        : `${personalInfo.firstName} ${personalInfo.lastName}`

      content = (
        <div className={styles.actor}>
          <Image
            src={personalInfo.photoUrl || staticProfilePhoto}
            width={24}
            height={24}
            alt={fullName}
          />
          <span>{fullName}</span>
        </div>
      )
    } else {
      content = <div className={styles.actor}>Another user</div>
    }

    return content
  }, [])

  const notificationHref = useCallback((notif: Notification) => {
    let href
    if (
      [
        NotificationType.EndorsementReceived,
        NotificationType.ApprovalReceived,
      ].includes(notif.type) &&
      profileId
    ) {
      href = `/p/${profileId}`
    } else if (notif.type === NotificationType.Other) {
      href = 'app/playbook-builder'
    } else if (notif.actor?.rootSnapshot?.id) {
      href = `/p/${notif.actor?.rootSnapshot?.id?.replace('snap:', '')}`
    }

    return href || '/app/team'
  }, [])

  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="100%"
          height="100%"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          style={{ width: '1.1em', height: '1.1em', stroke: '#777' }}
        >
          <path d="M22 17H2a3 3 0 0 0 3-3V9a7 7 0 0 1 14 0v5a3 3 0 0 0 3 3zm-8.27 4a2 2 0 0 1-3.46 0"></path>
        </svg>

        <span>Notifications</span>
      </div>

      {isLoading && (
        <div className={styles.loadingArea}>
          <LoadingBar width={`80%`} />
        </div>
      )}

      {!isLoading && notifications.length === 0 && (
        <div className={styles.noNotifications}>You have no notifications!</div>
      )}

      {notifications.map(notif => (
        <div
          className={styles.notificationWrapper}
          key={notif.id}
          onClick={() => markAsRead(notif.id)}
        >
          <Link
            className={styles.notificationItem}
            href={notificationHref(notif)}
          >
            {actorContent(notif)}

            <div className={styles.notificationContent}>
              {TYPES_TO_TEXT[notif.type]}
            </div>

            <div className={styles.viewCta}>view</div>
          </Link>
        </div>
      ))}

      {notifications.length > 0 && (
        <div className={styles.clearNotifsArea} onClick={markAllAsRead}>
          Mark all as read
        </div>
      )}
    </div>
  )
}
